import { CoreUiI18nEN } from './en';

export const CoreUiI18nDE: typeof CoreUiI18nEN = {
  'asyncComponent.loading': 'Lade...',
  'asyncComponent.loadingFailed': 'Laden fehlgeschlagen',

  'pagination.totalRowsLabel': 'Gesamtanzahl der Assets: {itemCount}',
  'core.markdown.toggleTextMode': 'Toggle Markdown-Text Modus',

  'filterBarInput.filterPlaceholder': 'Einträge filtern...',
  'filterBarInput.clearTooltip': 'Filter entfernen',

  'controls.textInputWithHiddenValue.showValue': 'Wert anzeigen',
  'controls.textInputWithHiddenValue.hideValue': 'Wert ausblenden',

  'select.valueNotInOptions': 'Der angegebene Wert konnte nicht in den auswählbaren Optionen gefunden werden',

  'clipboardButton.title': 'In Zwischenablage kopieren',
  'clipboardButton.notification.success': 'In Zwischenablage kopiert',
  'clipboardButton.notification.error': 'Konnte nicht in Zwischenablage kopieren',

  'itemSelectionList.selectBtn': 'Hinzufügen',

  'notice.header.info': 'Info',
  'notice.header.success': 'Erfolg',
  'notice.header.warning': 'Warnung',
  'notice.header.error': 'Fehler',

  'screenModal.close': 'Schließen',

  'LazyPanel.load': 'Daten laden',

  'MarkdownEditor.error':
    'Der Markdown-Text konnte aufgrund eines Syntax-Fehlers nicht umgewandelt werden. Beheben Sie den Fehler, damit er wieder korrekt angezeigt wird.',

  // ShowMoreElements
  'showMoreElements.showMoreLink': 'mehr ...',
  'showMoreElements.showAllLink': 'Alle anzeigen',

  'table.empty.hd': 'Keine Einträge gefunden',
  'table.pagination': '{currentPageStartRow}-{currentPageEndRow} von {itemCount}',

  'tree.loadMore': 'Mehr laden',
};
