import { DefinitionNode, DocumentNode, FragmentDefinitionNode } from 'graphql';

const isFragmentDefinition = (node: DefinitionNode): node is FragmentDefinitionNode =>
  node.kind === 'FragmentDefinition';

export function extractFragmentTypeCondition(node: DocumentNode, fragmentName?: string) {
  const fragmentDefinitions = node.definitions.filter(isFragmentDefinition);

  if (fragmentDefinitions.length > 1 && fragmentName === undefined) {
    throw new Error(
      `Found ${fragmentDefinitions.length} fragments. \`fragmentName\` must be provided when there is not exactly 1 fragment.`,
    );
  }

  const fragmentDefinition = fragmentName
    ? fragmentDefinitions.find((f) => f.name.value === fragmentName)
    : fragmentDefinitions[0];

  if (fragmentDefinition === undefined) {
    throw new Error('could not find the fragment definition');
  }

  if (fragmentDefinition.kind !== 'FragmentDefinition') {
    throw new Error('definition is not a fragment definition');
  }

  return fragmentDefinition.typeCondition.name.value;
}
