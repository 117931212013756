import { findReadableUnitConversion, UnitConverter } from './convertUnit';

const BASE = 1024;

const EXPONENT_MAP = {
  Byte: 0,
  KiB: 1,
  MiB: 2,
  GiB: 3,
  TiB: 4,
  PiB: 5,
};

export type ByteUnit = keyof typeof EXPONENT_MAP;

export const byteUnits: ByteUnit[] = ['Byte', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB'];

export const convertByteUnit: UnitConverter<ByteUnit> = (
  value: number,
  from: ByteUnit,
  to: ByteUnit,
  round = false,
) => {
  const result = (value * BASE ** EXPONENT_MAP[from]) / BASE ** EXPONENT_MAP[to];
  return round ? Math.round(result) : result;
};

export const findReadableByteUnit = (value: number, valueUnit: ByteUnit, round = false) =>
  findReadableUnitConversion((v, f, t) => convertByteUnit(v, f, t, round), byteUnits, valueUnit)(value);

/**
 * First letter (lowercased) of unit to ByteUnit value, e.g { m: 'MiB', ... }.
 */
const unitMatches: { [key: string]: ByteUnit } = byteUnits.reduce(
  (prev, next) => ({ ...prev, [next[0].toLowerCase()]: next }),
  {},
);

const byteUnitRegex = /((?:\d+|[,. ])+)\s*([bkmgtp])/i;
const thousandsSeparatorRegex = /[.](?=[\d.]*[{0,}][\,]?[\d]*)/g;

/**
 * Attempts to convert a string to a unit value.
 * Examples:
 *  '1gb'   = 1, GiB
 *  '2tib'  = 2, TiB
 *  '1000m' = 1000, MiB
 */
export function stringToByteUnitValue(value: string): { amount: number; unit: ByteUnit } | undefined {
  const match = value.match(byteUnitRegex);

  if (match === null) {
    return undefined;
  }

  const [, rawAmount, rawUnit] = match;

  return {
    amount: parseFloat(
      rawAmount
        // remove thousand seperators (dots, not bothering with the us/uk comma seperator).
        .replace(thousandsSeparatorRegex, '')
        // remove (single) decimal separator
        .replace(',', '.')
        // remove whitespace
        .replace(/ /g, ''),
    ),
    unit: unitMatches[rawUnit.toLowerCase()],
  };
}
