export { Box } from './Box';
export { Flex, FlexCentered, type FlexProps } from './Flex';
export { Grid } from './Grid';
export { Spacer } from './Spacer';
export { LightDescriptionElement } from './elements';
export { StyledLink, linkStyles } from './links';
export { overflowXStyle } from './overflowXStyle';
export type { ThemeSpace } from './props';
export { colors } from './props/colors';
export { styleIf } from './styleIf';
export { TxtureThemeProvider } from './theme';
export type { Theme, ThemeColors } from './theme';
export { theme } from './token';
export { Heading, Text, type TypoProps } from './typo';
import { theme as _theme } from './theme';
/** @deprecated Use context `useTheme` instead */
export const getStaticTheme = () => _theme;
