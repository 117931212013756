import type { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import * as React from 'react';
import { FormattedMessage as IntlFormattedMessage } from 'react-intl';

export interface Props<LanguageKeys> {
  id: LanguageKeys;
  description?: string;
  defaultMessage?: string;
  values?: Record<string, PrimitiveType | React.ReactElement | FormatXMLElementFn<React.ReactNode, React.ReactNode>>;
  className?: string;
  children?(...nodes: React.ReactNode[]): React.ReactElement;
}

/** FormattedMessage with className support. */
export function FormattedMessage<LanguageKeys extends string>({
  className,
  ...props
}: React.PropsWithChildren<Props<LanguageKeys>>) {
  return (
    <IntlFormattedMessage {...props}>
      {(...i18nValues) => {
        if (props.children) {
          return props.children(i18nValues);
        }
        // create a span wrapper element and pass all processed nodes. react-intl creates multiple nodes
        // if some of the values are react elements.
        return React.createElement('span', { className }, ...i18nValues);
      }}
    </IntlFormattedMessage>
  );
}
