import * as React from 'react';
import { FormattedTime as FormattedTimeIntl } from 'react-intl';
import { getStaticIntl } from '../IntlProvider';

interface Props {
  value: number;
  className?: string;
}

export const FormattedTime: React.FC<Props> = ({ value, className }) => {
  return (
    <span className={className}>
      <FormattedTimeIntl value={value} />
    </span>
  );
};

/** String only date renderer. */
export function formatTime(value: number) {
  return getStaticIntl().formatTime(value);
}
