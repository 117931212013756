import styled from '@emotion/styled';
import { Icon } from '@txt/core.ui/Icon';
import * as Immutable from 'immutable';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTimeoutFn } from 'react-use';

const NOTIFICATION_DURATION: { [key in Message['type']]: number } = {
  error: 10000,
  success: 3000,
};

const NotificationContext = React.createContext({
  showNotification: (msg: Message): void => {
    throw new Error(`Notification context is missing`);
  },
});

export function useNotifications() {
  return React.useContext(NotificationContext);
}

type Message = {
  type: 'success' | 'error';
  msgKey: string;
  msgValues?: Record<string, any>;
  msg?: string;
};

let msgIdCounter = 0;

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [messages, setMessages] = React.useState<Immutable.OrderedSet<Message & { id: number }>>(Immutable.OrderedSet);

  const showNotification = (msg: Message) => {
    msgIdCounter++;
    setMessages(messages.add({ ...msg, id: msgIdCounter }));
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      {messages.size > 0 && (
        <Styles.Wrap>
          {messages.map((msg) => (
            <NotificationMsg key={msg.id} message={msg} onHide={() => setMessages(messages.remove(msg))} />
          ))}
        </Styles.Wrap>
      )}
    </NotificationContext.Provider>
  );
};

type MsgProps = {
  message: Message;
  onHide: () => void;
};

const NotificationMsg: React.FC<MsgProps> = ({ message, onHide }) => {
  const [__isReady, cancel, reset] = useTimeoutFn(onHide, NOTIFICATION_DURATION[message.type]);

  return (
    <Styles.Notification onMouseEnter={cancel} onMouseLeave={reset} type={message.type}>
      {message.type === 'error' ? <Icon icon="exclamation-triangle" /> : <Icon icon="check" />}
      <FormattedMessage id={message.msgKey} values={message.msgValues} />
      {message.msg && <div>{message.msg}</div>}
    </Styles.Notification>
  );
};

const Styles = {
  Wrap: styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
  `,
  Notification: styled.div<{ type: Message['type'] }>`
    background: ${(props) => props.theme.palette.text};
    color: ${(props) => (props.type === 'error' ? props.theme.palette.status.error.main : '#fff')};
    padding: 1em;
    border-radius: 3px;
    animation: appear 0.4s;
    margin: 1em;

    @keyframes appear {
      0% {
        transform: translateY(100%);
        opacity: 0;
      }
    }
  `,
};
