import * as React from 'react';

export type AuthState = {
  isLoading: boolean;
  user?:
    | {
        type: 'TxtureSupportUser';
        id: string;
        email: string;
      }
    | {
        type: 'InsiderUser';
        id: string;
        email: string;
        firstname: string | null;
        lastname: string | null;
      };
};

export const AuthContext = React.createContext<AuthState>({
  isLoading: true,
});

export function canUserEditContent(auth: AuthState) {
  return auth.user?.type === 'TxtureSupportUser';
}

export function useCanUserEditContent() {
  const authContext = React.useContext(AuthContext);

  return canUserEditContent(authContext);
}
