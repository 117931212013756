import { getEnvironment } from '@txt/core.env';
import mixpanel from 'mixpanel-browser';
import { pushGoogleTrackingEvent } from './googleTracking';

export let isMixpanelInitialized = false;
const env = getEnvironment();
const PAGE_VIEW_IDENTIFIER = 'Page View';

/**
 * Initial the page tracker.
 * @see https://eu.mixpanel.com/project/2642111
 */
export function initPageTracker(options: { mixpanelTrackerId: string; proxyHost?: string }) {
  if (env === 'development') {
    console.log('Track::Usage tracking is disabled');
    // skip usage tracking for the dev instances
    return;
  }

  mixpanel.init(options.mixpanelTrackerId, {
    // debug: true, // enable for logging
    api_host: options.proxyHost ?? 'https://api-eu.mixpanel.com',
  });

  isMixpanelInitialized = true;
}

/** Track a user specific event. */
export function trackUserEvent(eventName: string | typeof PAGE_VIEW_IDENTIFIER, meta?: Record<string, any>) {
  // window.TXTURE_CONFIG is not set for the taxonomy and therefore can be undefined!
  if (env === 'development' || window.TXTURE_CONFIG?.txtureModeEnabled) {
    console.log('Track::UserEvent', eventName, meta);
  }

  if (isMixpanelInitialized) {
    // only track if initialized
    // e.g. skipped on the taxonomy and dev instances
    if (eventName === PAGE_VIEW_IDENTIFIER) {
      mixpanel.track_pageview(location);
    } else {
      mixpanel.track(eventName, meta);
    }
  }

  if (env !== 'development') {
    // remove email before sending to google analytics
    const googleMetaObj = meta?.email ? { ...meta, email: undefined } : meta;

    // track to google analytics (data is send only if registered)
    pushGoogleTrackingEvent({
      event: eventName,
      ...googleMetaObj,
    });
  }
}

type Location = {
  pathname: string;
  search: string;
  hash: string;
};

export function trackPageView(location: Location) {
  // TODO: we could improve the page view data:
  // currently we can not aggregate data with {id} or {providerNames} in the url
  // either we track those pages individually with fixed identifier, or we parse and replace pathnames for txtureIds
  trackUserEvent(PAGE_VIEW_IDENTIFIER, location);
}

/**
 * Track a user login.
 * We check for txture internal user and enhance the event data.
 */
export function trackUserLogin<
  T extends {
    userId: string;
    email?: string;
    fields: {
      isAdmin?: boolean;
      type?: 'user' | 'externalApplication' | 'TxtureSupportUser' | 'InsiderUser';
    };
  },
>(user: T) {
  const isTxtInternalUser = !!user.email && isTxtureInternalUser(user.email);
  trackUserEvent('login', { ...user, isTxtInternalUser });

  if (isTxtInternalUser) {
    trackUserEvent('txtureUserLogin');
  }

  if (isMixpanelInitialized) {
    if (isTxtInternalUser) {
      mixpanel.register({ isTxtInternalUser });
    }

    const isCloudInsiderUser = user.fields.type === 'InsiderUser' || user.fields.type === 'TxtureSupportUser';

    // https://developer.mixpanel.com/docs/javascript
    // In order to send profile updates, you must call mixpanel.identify in addition to mixpanel.people.set,
    // which empowers you to create profiles for only the users of your choice.
    mixpanel.identify(user.userId);
    // $ symbol is used for mixpanel specific fields (e.g. $email)
    // https://help.mixpanel.com/hc/en-us/articles/115004708186
    mixpanel.people.set({ ...user.fields, $email: isCloudInsiderUser ? user.email : undefined });
  }
}

/**
 * Check if a user should be excluded from the tracking.
 * We are pushing a google tag event which then is used to blacklist the Google Analytics tracking.
 */
function isTxtureInternalUser(userEmail: string) {
  return userEmail.endsWith('@txture.io') || userEmail.endsWith('@cloudinsider.com');
}
