import React from 'react';
import { Currency, defaultCurrency } from '../conversion/convertUnitCurrency';

type CurrencySetter = (currency: Currency) => void;

export type CurrencyContextType = [Currency, CurrencySetter];

export const CurrencyContext = React.createContext<CurrencyContextType>([defaultCurrency, () => undefined]);

/**
 * Current currency info based on local state.
 */
export const CurrencyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currency, setCurrency] = React.useState<Currency>(defaultCurrency);

  const contextValue: CurrencyContextType = React.useMemo(
    () => [currency, (value: Currency) => setCurrency(value)],
    [currency],
  );

  return <CurrencyContext.Provider value={contextValue}>{children}</CurrencyContext.Provider>;
};

/**
 * Use the currency context.
 */
export function useUserCurrency(): CurrencyContextType {
  return React.useContext(CurrencyContext);
}
