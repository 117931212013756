import { en } from './en';

export const de: typeof en = {
  'button.OK': 'OK',
  'button.Cancel': 'Abbrechen',
  'button.Yes': 'Ja',
  'button.No': 'Nein',
  'button.Save': 'Speichern',
  'button.Discard': 'Verwerfen',
  'button.Confirm': 'Bestätigen',
  'date.todayAt': 'Heute um',
  'date.yesterdayAt': 'Gestern um',
  'price.conversion1': '{fromCurrency} zu {currency} umrechnung.',
  'price.conversion2': 'konvertiert von {price}',
  'price.perMonth': ' / mo',
  'price.unknown': 'Preisdaten sind nicht verfügbar.',
  'intl.FormattedPricePerMonth.pricePartsMissing':
    'Für manche Produkte sind keine Preisdaten vorhanden. Der eigentliche Preis ist daher eventuell höher..',
};
