import * as React from 'react';
import { IconName } from '../Icon';
import { Button } from './Button';

type Props = React.ComponentProps<typeof Button> & {
  icon: IconName;
  isLoading: boolean;
};

export const ButtonIconLoading: React.FC<Props> = ({ icon, isLoading, children, ...props }) => (
  <Button {...props} disabled={isLoading || props.disabled}>
    {isLoading ? (
      <Button.Icon icon="spinner" fixedWidth className="fa-pulse fa-3x fa-fw" />
    ) : (
      <Button.Icon fixedWidth icon={icon} />
    )}
    {children}
  </Button>
);
