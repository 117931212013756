import { faDollarSign, faEuroSign, faPoundSign, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { UnitConverter } from './convertUnit';

export const currencies = ['USD', 'EUR', 'CNY', 'GBP', 'CHF'] as const;
export type Currency = (typeof currencies)[number];
export const defaultCurrency: Currency = 'USD';

/**
 * Currency conversion rates to USD.
 * sauce: org.txture/org.txture.core/src/main/java/org/txture/core/service/impl/CurrencyConversionServiceImpl.kt
 * Note: on conversionRate change, please notify @David ( StackIT crawlers need to be reexecuted)
 */
const conversionRates: Record<Currency, number> = {
  USD: 1 as const,
  EUR: 1.082818 as const,
  CNY: 0.156336 as const,
  GBP: 1.302729 as const,
  CHF: 1.053867 as const,
};

/** Symbols for supported currencies */
export const currencySymbol: { [P in Currency]?: string } = {
  USD: '$',
  EUR: '€',
  GBP: '£',
};

/** Icons for supported currencies */
export const currencyIcon: { [P in Currency]?: IconDefinition } = {
  USD: faDollarSign,
  EUR: faEuroSign,
  GBP: faPoundSign,
};

export const convertCurrency: UnitConverter<Currency> = (value: number, from: Currency, to: Currency) => {
  return value * conversionRates[from] * (1 / conversionRates[to]);
};
