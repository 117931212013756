import styled from '@emotion/styled';
import { system } from 'styled-system';
import { ThemeColors } from './theme';

const color = system({
  color: {
    property: 'color',
    scale: 'palette',
  },
});

export const Spacer = styled.hr<{ color?: ThemeColors }>`
  ${color}
  border: none; // reset
  margin: 0;
  border-top: 1px solid;
  border-left: 1px solid;
  border-color: inherit;
  align-self: stretch;
  grid-column: 1/-1;
  background: none;
  & + &,
  &:last-child {
    display: none;
  }
`;

Spacer.defaultProps = {
  color: 'grey.100',
};
