/// <reference types="../typings/emotion" />
import styled from '@emotion/styled';
import { getFormattedErrorMessage } from '@txt/core.utils/error';
import * as React from 'react';
import { FormattedMessage } from './i18n';
import { Icon } from './Icon';

type Props = {
  heading?: React.ReactNode;
  children: any;
};

export const ErrorDisplay: React.FC<Props> = ({ heading, children }) => {
  const { errMsg, title } = extractMsgContent(children);
  return (
    <Styles.Wrap>
      <Styles.ErrorHd>
        <Icon icon="exclamation-triangle" />{' '}
        {heading || title || <FormattedMessage id="asyncComponent.loadingFailed" />}
      </Styles.ErrorHd>
      <Styles.Msg>{errMsg}</Styles.Msg>
    </Styles.Wrap>
  );
};

/**
 * Try to display the error (can either be a react component, text, error object, ...)
 */
function extractMsgContent(err: any): {
  title?: string;
  errMsg: React.ReactNode;
} {
  if (React.isValidElement(err)) {
    return {
      errMsg: err,
    };
  }
  try {
    return getFormattedErrorMessage(err);
  } catch (ex) {
    // can happen if e.g. there are nested object data structures
    console.error('Error while displaying error msg', ex);
    return {
      errMsg: 'Unknown error',
    };
  }
}

const Styles = {
  Wrap: styled.div`
    border: 1px solid ${(props) => props.theme.palette.status.error.main};
    padding: 1em;
    border-radius: 0.2em;
  `,
  ErrorHd: styled.div`
    color: ${(props) => props.theme.palette.status.error.main};
    font-weight: 600;
    margin-bottom: 0.3em;
  `,
  Msg: styled.div``,
};

export const ErrorMessage = styled.div`
  color: ${(props) => props.theme.palette.status.error.main};
`;
