import styled from '@emotion/styled';
import { TxtureThemeProvider } from '@txt/core.styles';
import { ErrorBoundary } from '@txt/core.ui/ErrorBoundary';
import { LoadingIndicator } from '@txt/core.ui/LoadingIndicator';
import { CurrencyProvider, DocumentTitleContext, OutsideProvider } from '@txt/core.utils/hooks';
import { TxtIntlProvider } from '@txt/core/intl';
import React, { Suspense } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useTitle } from 'react-use';
import { InsiderApolloProvider } from './ApolloProvider';
import { Auth } from './auth/Auth';
import { InsiderContext } from './components/InsiderContext';
import { NotificationProvider } from './components/Notifications';
import { GlobalInsiderStyles } from './layout/GlobalInsiderStyles';

const InsiderRoutes = React.lazy(() => import(/* webpackChunkName: "insider" */ './InsiderRoutes'));
const LandingPage = React.lazy(() => import(/* webpackChunkName: "insider" */ '@txt/insider/ui.landingPage'));

const insiderContext = { isCloudInsider: true };
const useCITitle = (title?: string) => useTitle(`${title ? `${title} - ` : ''}Cloud Insider - Txture`);

export const App: React.FC = () => {
  return (
    <TxtIntlProvider>
      <CurrencyProvider>
        <InsiderContext.Provider value={insiderContext}>
          <DocumentTitleContext.Provider value={useCITitle}>
            <TxtureThemeProvider>
              <GlobalInsiderStyles />
              <ErrorBoundary>
                <OutsideProvider>
                  {(mouseHandlers) => (
                    <Styles.PageWrap {...mouseHandlers}>
                      <Router>
                        <InsiderApolloProvider>
                          <NotificationProvider>
                            <Suspense fallback={<LoadingIndicator backgroundPreset="grey" />}>
                              <Switch>
                                <Route path="/" exact component={LandingPage} />

                                <Route
                                  path="*"
                                  render={() => (
                                    <Auth>
                                      <InsiderRoutes />
                                    </Auth>
                                  )}
                                />
                              </Switch>
                            </Suspense>
                          </NotificationProvider>
                        </InsiderApolloProvider>
                      </Router>
                    </Styles.PageWrap>
                  )}
                </OutsideProvider>
              </ErrorBoundary>
            </TxtureThemeProvider>
          </DocumentTitleContext.Provider>
        </InsiderContext.Provider>
      </CurrencyProvider>
    </TxtIntlProvider>
  );
};

const Styles = {
  PageWrap: styled.div`
    display: flex;
    min-height: 100vh; // ensures that we cannot click outside the element
    flex-direction: column;
  `,
};
