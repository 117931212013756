import { isFunction } from 'lodash';
import * as React from 'react';

export const mergeRefs =
  <T>(...refs: (React.ForwardedRef<T> | undefined)[]) =>
  (ref: T) => {
    refs.forEach((r) => {
      if (r) {
        if (isFunction(r)) {
          r(ref);
        } else {
          r.current = ref;
        }
      }
    });
  };
