import { UnitConverter } from './convertUnit';

const MILLIMETER = 1;
const CENTIMETER = 10;
const METER = 1000;
const KILOMETER = METER * 1000;
const INCH = 25.4;
const DTP_POINT = (1 / 72) * INCH; // aka pt
const PX_96DPI = INCH / 96.0;

const CONVERSION_MAP = {
  mm: MILLIMETER,
  cm: CENTIMETER,
  m: METER,
  km: KILOMETER,
  in: INCH,
  pt: DTP_POINT,
  px: PX_96DPI,
};

export type LengthUnit = keyof typeof CONVERSION_MAP;

export const convertLengthUnit: UnitConverter<LengthUnit> = (value: number, from: LengthUnit, to: LengthUnit) =>
  (value * CONVERSION_MAP[from]) / CONVERSION_MAP[to];
