import styled from '@emotion/styled';
import * as React from 'react';
import { TypoProps, typoStyles } from './utils';

// Inspired by Chakras `as`prop (https://chakra-ui.com/docs/components/text)
type TextTag = Extract<
  keyof React.JSX.IntrinsicElements,
  | 'abbr'
  | 'b'
  | 'cite'
  | 'del'
  | 'div'
  | 'em'
  | 'i'
  | 'ins'
  | 'kbd'
  | 'label'
  | 'mark'
  | 'p'
  | 'pre'
  | 's'
  | 'samp'
  | 'span'
  | 'sub'
  | 'sup'
  | 'u'
>;

export const Text = styled.span<TypoProps>((props) =>
  typoStyles({
    ...props,
    fontSize: props.fontSize ?? 'md',
    fontWeight: props.fontWeight ?? 'normal',
    lineHeight: props.lineHeight ?? 'body',
  }),
) as <T extends TextTag>(props: PolymorphicComponentPropsWithRef<T, TypoProps>) => React.ReactElement;
