import * as React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { formatDate } from './FormattedDate';
import { formatTime } from './FormattedTime';

interface Props {
  value: number;
  className?: string;
}

export const FormattedDateTime: React.FC<Props> = ({ value, className }) => (
  <span className={className}>
    <FormattedDate value={value} /> <FormattedTime value={value} />
  </span>
);

/** String only date-time renderer. */
export function formatDateTime(value: number) {
  return `${formatDate(value)} ${formatTime(value)}`;
}
