import styled from '@emotion/styled';
import * as Sentry from '@sentry/browser';
import { Heading, TxtureThemeProvider } from '@txt/core.styles';
import * as React from 'react';
import { TxtureLogo } from './TxtureLogo';
import { ButtonGroup, Button } from './button';

interface Props {
  withReloadBtn?: boolean;
  withRetryBtn?: boolean;
  onRetry?: () => void;
  children?: React.ReactNode;
  /** Optionally override the rendered error contents with something creative of your own. */
  errorFallback?: (retryBtn: React.ReactNode) => React.ReactNode;
}

interface State {
  hasError: boolean;
  isChunkLoadingError: boolean;
}

/** React error boundary: reports error which are thrown in react lifecycle methods. */
export class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
    isChunkLoadingError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    // Specific case for chunk loading errors (happens for network errors and bundle updates).
    if (/Loading [^]+ chunk [^]+ failed/.test(error.message)) {
      this.setState({ isChunkLoadingError: true });
      return; // No sentry logging needed.
    }

    try {
      Sentry.withScope((scope) => {
        scope.setExtras({ info });
        Sentry.captureException(error);
      });
      console.debug(error);
    } catch (e) {
      console.error(`Error while sending error to server: `, e, ' original error: ', error, info);
    }
  }

  render() {
    const { withReloadBtn = false, withRetryBtn, errorFallback } = this.props;
    const { hasError, isChunkLoadingError: isChunkError } = this.state;

    if (hasError) {
      const retryBtn = withRetryBtn && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            this.props.onRetry?.();
            this.setState({ hasError: false });
          }}
        >
          <Button.Icon icon="repeat" />
          <span>Retry</span>
        </Button>
      );

      if (errorFallback) {
        return errorFallback(retryBtn);
      }

      return (
        <TxtureThemeProvider>
          <Styles.Wrap>
            <Styles.TxtureLogo />
            <Styles.Message>
              {isChunkError ? (
                <Heading fontSize="xl3" my="4">
                  We're sorry — There was a loading error.
                </Heading>
              ) : (
                <Heading fontSize="xl3" my="4">
                  We're sorry — something's gone wrong.
                </Heading>
              )}

              {isChunkError && navigator.onLine ? (
                <p>Txture has been updated. Reload the page to load the latest Txture version.</p>
              ) : isChunkError && !navigator.onLine ? (
                <p>We noticed a network error. Reload the page to load the latest Txture version.</p>
              ) : (
                <p>We apologise and are fixing the problem. Please try again at a later stage.</p>
              )}

              <ButtonGroup justifyContent="flex-end">
                {withReloadBtn && (
                  <Button variant="contained" color="primary" onClick={() => document.location.reload()}>
                    <Button.Icon icon="repeat" />
                    <span>Reload the page</span>
                  </Button>
                )}
                {retryBtn}
              </ButtonGroup>
            </Styles.Message>
          </Styles.Wrap>
        </TxtureThemeProvider>
      );
    }

    return this.props.children;
  }
}

const Styles = {
  Wrap: styled.div`
    display: flex;
    min-height: 0;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  `,

  TxtureLogo: styled(TxtureLogo)`
    height: 2em;
    width: auto;
    margin-bottom: 1em;
  `,

  Message: styled.div`
    max-width: 600px;
    padding: 1em 2em;
    background: #fff;
    width: 100%;
    border-radius: 4px;
    position: relative;
    border-top: 3px solid #f56200; /* direct import as ErrorBoundary is outer most component (before init of theme) */
  `,
};
