import { type Currency, defaultCurrency } from '@txt/core.utils/conversion';
import { roundToFirstTwoNonZeroDigits } from '@txt/core.utils/currency';
import { getStaticIntl } from './IntlProvider';

export type RoundingMode = 'default' | 'none' | 'full';

type Options = {
  value: number;
  currency?: Currency;
  rounding?: RoundingMode;
  fixedDigits?: boolean;
  showSymbol?: boolean;
  compact?: boolean;
};

const roundingConfig = {
  default: { min: 2, max: 2 },
  none: { min: 2, max: 10 },
  full: { min: 0, max: 0 },
};

/**
 * Formats a price value for displaying, does not convert.
 */
export function displayCurrency({
  value,
  currency = defaultCurrency,
  rounding = 'default',
  showSymbol = true,
  compact = false,
}: Options) {
  const smallNumberRounding = value > -1 && value < 1 && rounding === 'default';

  if (smallNumberRounding) {
    value = roundToFirstTwoNonZeroDigits(value);
  }

  return getStaticIntl().formatNumber(value, {
    style: showSymbol ? 'currency' : undefined,
    currency: showSymbol ? currency : undefined,
    minimumFractionDigits: roundingConfig[rounding].min,
    maximumFractionDigits: smallNumberRounding ? roundingConfig.none.max : roundingConfig[rounding].max,
    notation: compact ? 'compact' : undefined,
  });
}
