/**
 * @fileoverview txture environment settings. These configs should be configured
 * in the webpack config with the webpack.DefinePlugin.
 */

type Env = 'development' | 'production';

export function getEnvironment(): Env {
  return process.env.NODE_ENV === 'development' ? 'development' : 'production';
}

/** Returns the build commit hash. */
export function getBuildVersion() {
  return process.env.TXT_BUILD_VERSION;
}
