import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getApolloCache } from '@txt/core.utils/graphQL';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

export const InsiderApolloProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const history = useHistory();
  const [clientRef] = React.useState(() => createApolloProvider(history.push));
  return <ApolloProvider client={clientRef}>{children}</ApolloProvider>;
};

function createApolloProvider(redirect: (url: string) => void) {
  // subscribe to all errors
  const errorLink = onError(({ graphQLErrors = [], networkError }) => {
    // redirect to billing page on all subscription errors
    const isSubscriptionError = graphQLErrors.some((err) => err.extensions?.code === '403');
    if (isSubscriptionError) {
      redirect('/subscribe');
    }
  });

  const httpLink = createHttpLink({
    uri: '/api',
  });

  const link = ApolloLink.from([errorLink, httpLink]);

  return new ApolloClient({
    cache: getApolloCache(),
    link,
  });
}
