import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useQuery as useApolloQuery,
} from '@apollo/client';
import { ErrorDisplay } from '@txt/core.ui/ErrorDisplay';
import { LoadingIndicator } from '@txt/core.ui/LoadingIndicator';
import * as React from 'react';

export function useQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
) {
  const queryResult = useApolloQuery(query, options);

  React.useEffect(() => {
    if (queryResult.error) {
      console.error(queryResult.error);
    }
  }, [queryResult.error]);

  const loadingOrErrCmp = queryResult.error ? (
    <ErrorDisplay>{queryResult.error.message}</ErrorDisplay>
  ) : queryResult.loading ? (
    <LoadingIndicator />
  ) : null;

  return {
    ...queryResult,
    loadingOrErrCmp,
  };
}

type RequiredFields<T> = { [K in keyof T]-?: undefined extends T[K] ? never : T[K] extends never ? never : K }[keyof T];

type Query<TData, TVariables extends OperationVariables> =
  RequiredFields<TVariables> extends never
    ? (options?: QueryHookOptions<TData, TVariables>) => ReturnType<typeof useQuery<TData, TVariables>>
    : (options: QueryHookOptions<TData, TVariables>) => ReturnType<typeof useQuery<TData, TVariables>>;

export const createUseQuery = <TData extends any = any, TVariables extends OperationVariables = OperationVariables>(
  query: TypedDocumentNode<TData, TVariables>,
) => {
  return ((options?: TVariables) => useQuery(query, options)) as Query<TData, TVariables>;
};
