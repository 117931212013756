import styled from '@emotion/styled';
import {
  AlignSelfProps,
  FlexBasisProps,
  FlexGrowProps,
  FlexProps,
  FlexShrinkProps,
  HeightProps,
  OrderProps,
  OverflowProps,
  WidthProps,
  alignSelf,
  compose,
  flex,
  flexBasis,
  flexGrow,
  flexShrink,
  height,
  order,
  overflow,
  overflowX,
  overflowY,
  width,
} from 'styled-system';
import { PropsApi, styleProps } from './props';

const flexItem = compose(
  alignSelf,
  flex,
  flexBasis,
  flexGrow,
  flexShrink,
  order,
  overflow,
  overflowX,
  overflowY,
  width,
  height,
);

interface FlexItemProps
  extends AlignSelfProps,
    OrderProps,
    OverflowProps,
    FlexGrowProps,
    FlexShrinkProps,
    FlexBasisProps,
    FlexProps,
    WidthProps,
    HeightProps {}

interface BoxProps extends FlexItemProps, PropsApi {}

/**
 * Simple core element for creating layouts.
 * To be used within the `Flex` and `Grid` components.
 */
export const Box = styled.div<BoxProps>(flexItem, styleProps);
