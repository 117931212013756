import { findReadableUnitConversion, UnitConverter } from './convertUnit';

const MS = 1;
const SEC = MS * 1000;
const MIN = SEC * 60;
const HOUR = MIN * 60;
const DAY = HOUR * 24;
const MONTH = DAY * 31;

const CONVERSION_MAP = {
  MS,
  SEC,
  MIN,
  HOUR,
  DAY,
  MONTH,
};

export type TimeUnit = keyof typeof CONVERSION_MAP;

export const timeUnits: TimeUnit[] = ['MS', 'SEC', 'MIN', 'HOUR', 'DAY', 'MONTH'];

export const convertTimeUnit: UnitConverter<TimeUnit> = (value: number, from: TimeUnit, to: TimeUnit) =>
  (value * CONVERSION_MAP[from]) / CONVERSION_MAP[to];

export const findReadableTimeUnit = (value: number, valueUnit: TimeUnit) =>
  findReadableUnitConversion(convertTimeUnit, timeUnits, valueUnit)(value);
