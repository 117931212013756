import * as React from 'react';
import { useDebouncedValue } from './useDebouncedValue';

export function useDebouncedState<T>(
  initialValue: T,
  delay: number,
): [value: T, debouncedValue: T, setValue: React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = React.useState(initialValue);
  const debouncedValue = useDebouncedValue(value, delay);
  return [value, debouncedValue, setValue];
}
