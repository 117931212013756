import * as React from 'react';
import { FormattedDate as FormattedDateIntl } from 'react-intl';
import { getStaticIntl } from '../IntlProvider';

interface Props {
  value: number;
  className?: string;
}

export const FormattedDate: React.FC<Props> = ({ value, className }) => {
  return (
    <span className={className}>
      <FormattedDateIntl value={value} />
    </span>
  );
};

/** String only date renderer. */
export function formatDate(value: number | Date) {
  return getStaticIntl().formatDate(value);
}
