import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const PAGE_CONTENT_WIDTH = 1340;

export const ContentWrap = styled.div`
  max-width: ${PAGE_CONTENT_WIDTH}px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 1em;
`;

export const LightBgSection = styled.div<{ fillSpace?: boolean; padded?: boolean }>`
  background: ${(props) => props.theme.palette.grey[50]};
  ${(props) => props.fillSpace && 'flex: 1; display: flex; flex-direction: column;'}
  ${(props) => props.padded && 'padding: 1em;'}

  @media print {
    background: transparent;
  }
`;

export const ActiveCardStyles = css`
  background: #fff;
  padding: 1em;
  box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.1);
`;

export const ContentSection = styled.div<{ withShadow?: boolean; withoutMargin?: boolean; withoutPadding?: boolean }>`
  background: #fff;
  ${(p) => (p.withoutPadding ? undefined : 'padding: 1em;')}
  ${(p) => (p.withoutMargin ? undefined : 'margin: 1em 0;')}
  ${(props) =>
    props.withShadow &&
    `
    box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.1);
  `}
`;

export const inputStyling = css`
  height: 36px;
  width: 300px;
  padding: 0 11px;
  border: solid 1px;
  border-radius: 2px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: #424246;
`;

export const Input = styled.input`
  ${inputStyling}
`;
