import { get } from 'lodash';
import { Theme } from './theme';

/**
 * Helper to access our theme values in emotion styles.
 * e.g.
 * ```
 * styled.span`
 *   color: ${theme('palette.grey.600')};
 * `
 * ```
 */
export const theme =
  (key: LeavePath<Theme>) =>
  ({ theme }: { theme: Theme }) =>
    get(theme, key);
