import { UnitConverter } from './convertUnit';

const BASE = 1000;

const EXPONENT_MAP = {
  g: 0,
  kg: 1,
  t: 2,
};

export type MassUnit = keyof typeof EXPONENT_MAP;

export const convertMassUnit: UnitConverter<MassUnit> = (
  value: number,
  from: MassUnit,
  to: MassUnit,
  round = false,
) => {
  const result = (value * BASE ** EXPONENT_MAP[from]) / BASE ** EXPONENT_MAP[to];
  return round ? Math.round(result) : result;
};
