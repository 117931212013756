import { type Placement } from '@popperjs/core';
import { bind } from '@txt/core.utils/bind';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { default as Measure } from 'react-measure';
import { Manager, Popper, Reference } from 'react-popper';

import './tooltip.scss';

/** standard tooltip delay (in ms) */
export const TOOLTIP_DELAY = 400;

const modifiers = [
  {
    name: 'offset',
    options: {
      offset: [5, 5],
    },
  },
];

export type TooltipProps = Omit<Props, 'display'>;

/**
 * An inline tooltip wrapper: just wrap it around the component you want a tooltip for
 *
 * e.g. button with tooltip
 * <TooltipInlineBlock content={<MyTooltipContent />}>
 *  <button>
 * </TooltipInlineBlock>
 */
export const TooltipInlineBlock: React.FC<TooltipProps> = ({ children, ...props }) => (
  <Tooltip {...props} display="inline-block">
    {children}
  </Tooltip>
);

/**
 * A block tooltip wrapper: just wrap it around the component you want a tooltip for
 *
 * e.g. button with tooltip
 * <TooltipDiv content={<MyTooltipContent />}>
 *  <button>
 * </TooltipDiv>
 */
export const TooltipBlock: React.FC<TooltipProps> = ({ children, ...props }) => (
  <Tooltip {...props} display="block">
    {children}
  </Tooltip>
);

export type TooltipPlacement = Placement;

interface Props {
  /** default placement 'auto' should work for most cases */
  place?: TooltipPlacement;
  content: React.ReactNode;
  display?: 'inline' | 'block' | 'inline-block';
  /** delay in MS */
  delay?: number;
  hideDelay?: number;
  className?: string;
  /** defaults to false */
  disabled?: boolean;
  /** defaults to dark */
  style?: 'dark' | 'light';
  children:
    | React.ReactNode
    | ((registerProps: {
        ref: React.Ref<any>;
        onMouseEnter: () => void;
        onMouseLeave: (e: React.MouseEvent | React.FocusEvent) => void;
        onFocus: () => void;
        onBlur: (e: React.MouseEvent | React.FocusEvent) => void;
      }) => React.ReactNode);
}

interface State {
  update: number;
}

/**
 * known problems:
 *  - onMouseLeave does not bubble correctly for disabled buttons (see https://github.com/facebook/react/issues/4251)
 *    => use <Button disabled /> which is not really disabled
 * - does not work for elements with position: absolute
 */
export class Tooltip extends React.Component<Props, State> {
  state = { update: Date.now() };
  timer: null | number = null;
  hideTimer: null | number = null;
  isOpen = false;

  componentWillUnmount(): void {
    this.cancelTimeout();
  }

  @bind
  cancelTimeout() {
    if (this.timer !== null) {
      window.clearTimeout(this.timer);
    }

    if (this.hideTimer !== null) {
      window.clearTimeout(this.hideTimer);
    }
  }

  @bind
  open() {
    const { delay = 0 } = this.props;
    this.cancelTimeout();

    if (this.isOpen === true) {
      return;
    } else if (!delay) {
      this.isOpen = true;
      this.setState({ update: Date.now() });
    } else {
      const self = this;
      self.timer = window.setTimeout(() => {
        self.timer = null;
        self.isOpen = true;
        self.setState({ update: Date.now() });
      }, delay);
    }
  }

  @bind
  close(e: React.MouseEvent | React.FocusEvent) {
    // console.debug('close', e);
    const { hideDelay = 0 } = this.props;
    this.cancelTimeout();

    if (this.isOpen === false) {
      return;
    } else if (!hideDelay) {
      this.isOpen = false;
      this.setState({ update: Date.now() });
    } else {
      const self = this;
      self.hideTimer = window.setTimeout(() => {
        self.hideTimer = null;
        self.isOpen = false;
        self.setState({ update: Date.now() });
      }, hideDelay);
    }
  }

  render() {
    const {
      children,
      content,
      place = 'auto',
      display,
      className = '',
      disabled = false,
      style: tooltipStyle = 'dark',
    } = this.props;
    const isOpen = this.isOpen && !disabled;

    const renderTooltipContent = () => {
      if (!isOpen || !content) {
        return null;
      }
      return ReactDOM.createPortal(
        <div className={`tooltip-component ${className}`} onMouseEnter={this.cancelTimeout}>
          <Popper placement={place} modifiers={modifiers}>
            {({ update, ref, placement, style, arrowProps }) => {
              return (
                <div
                  ref={ref}
                  style={style}
                  data-popper-placement={placement}
                  className={`popper popper--${tooltipStyle}`}
                >
                  {/* the Measure-div watches for content size changes and triggers a popper update so the popper can be repositioned */}
                  <Measure onResize={() => update()}>
                    {({ measureRef }) => (
                      <div ref={measureRef} className="popper__measured-content">
                        {content}
                      </div>
                    )}
                  </Measure>
                  <div className="popper__arrow" ref={arrowProps.ref} style={arrowProps.style} />
                </div>
              );
            }}
          </Popper>
        </div>,
        document.body,
      );
    };

    if (typeof children === 'function') {
      // new API without div wrappers
      return (
        <Manager>
          <Reference>
            {({ ref }) =>
              children({
                ref,
                onMouseEnter: this.open,
                onMouseLeave: this.close,
                onFocus: this.open,
                onBlur: this.close,
              })
            }
          </Reference>

          {renderTooltipContent()}
        </Manager>
      );
    }

    return (
      <div
        onMouseLeave={this.close} // when the content resizes the position of the popper might be over the <Target> and therefore the onMouseLeave of <Target> triggers
        className={`tooltip-component display-${display} ${className}`}
      >
        <Manager>
          <Reference>
            {({ ref }) => (
              <div
                ref={ref}
                className="tooltip-wrapper"
                onMouseEnter={this.open}
                // onMouseLeave={this.close}
                onFocus={this.open}
                onBlur={this.close}
              >
                <div className="tooltip-wrapper2">{children}</div>
              </div>
            )}
          </Reference>

          {renderTooltipContent()}
        </Manager>
      </div>
    );
  }
}
