type OnChangeCallback = (isDebugModeActive: boolean) => void;
const callbacks: OnChangeCallback[] = [];

/**
 * Registers the debug mode helper function on the window object.
 * This function should be called in the bundle init to ensure that we can activate the debug mode
 * via the console/bookmarklet.
 * @see https://txture.atlassian.net/wiki/spaces/SALES/pages/2951151617/Txture+Debug+Mode
 * @param onChangeCallback optional callback which is called when the debug mode is activated.
 */
export function registerDebugModeHandler(onChangeCallback?: OnChangeCallback) {
  setTxtureDebugConfig({ enableTxtureMode: setDebugMode });

  if (onChangeCallback) {
    callbacks.push(onChangeCallback);
  }
}

/** Enable / disable the debug mode. */
export function setDebugMode(isActive: boolean) {
  if (isActive === isDebugModeActive()) {
    return;
  }

  setTxtureDebugConfig({ txtureModeEnabled: isActive });

  for (const callback of callbacks) {
    callback(isActive);
  }
}

/**
 * Returns `true` if the debug mode is active. Use to show additional debug buttons
 * or helpful info in the UI which is usually hidden for customers.
 */
export function isDebugModeActive() {
  return !!window.TXTURE_CONFIG?.txtureModeEnabled;
}

export function setTxtureDebugConfig(config: Partial<typeof window.TXTURE_CONFIG>) {
  window.TXTURE_CONFIG = {
    ...window.TXTURE_CONFIG,
    ...config,
  };
}
