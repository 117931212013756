export const CoreUiI18nEN = {
  'asyncComponent.loadingFailed': 'Loading failed',
  'asyncComponent.loading': 'Loading...',

  'pagination.totalRowsLabel': 'Total Assets: {itemCount}',
  'core.markdown.toggleTextMode': 'Toggle markdown-text mode',

  'filterBarInput.filterPlaceholder': 'Filter items...',
  'filterBarInput.clearTooltip': 'Clear filter',

  'controls.textInputWithHiddenValue.showValue': 'show value',
  'controls.textInputWithHiddenValue.hideValue': 'hide value',

  'select.valueNotInOptions': 'The provided value could not be found in the selectable options',

  'clipboardButton.title': 'Copy to clipboard',
  'clipboardButton.notification.success': 'Copied to clipboard',
  'clipboardButton.notification.error': 'Could not copy to clipboard',

  'itemSelectionList.selectBtn': 'Add',

  'notice.header.info': 'Info',
  'notice.header.success': 'Success',
  'notice.header.warning': 'Warning',
  'notice.header.error': 'Error',

  'screenModal.close': 'Close',

  'LazyPanel.load': 'Load data',

  'MarkdownEditor.error':
    "The Markdown couldn't be transformed because of an syntax error. Fix the error to make it display correctly again.",

  // ShowMoreElements
  'showMoreElements.showMoreLink': 'more ...',
  'showMoreElements.showAllLink': 'Show all',

  'table.empty.hd': 'No items found',
  'table.pagination': '{currentPageStartRow}-{currentPageEndRow} of {itemCount}',

  'tree.loadMore': 'Load more',
};
