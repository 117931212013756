import styled from '@emotion/styled';
import { Box } from './Box';
import { Flex } from './Flex';

export const Grid = {
  Row: styled(Flex)<{ padded?: boolean }>`
    ${(p) => p.padded && `gap: 1em;`}

    @media (max-width: 900px) {
      display: block;
    }
  `,
  Col: styled(Box)<{ padded?: boolean }>`
    @media (min-width: 901px) {
      width: 50%;
      ${(props) => props.padded && `padding: 0 2em;`}
    }
  `,
};
