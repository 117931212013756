import { UnitConverter } from './convertUnit';

const BASE = 1000;

const EXPONENT_MAP = {
  Wh: 0,
  kWh: 1,
  MWh: 2,
  GWh: 3,
};

export type EnergyUnit = keyof typeof EXPONENT_MAP;

export const convertEnergyUnit: UnitConverter<EnergyUnit> = (
  value: number,
  from: EnergyUnit,
  to: EnergyUnit,
  round = false,
) => {
  const result = (value * BASE ** EXPONENT_MAP[from]) / BASE ** EXPONENT_MAP[to];
  return round ? Math.round(result) : result;
};
