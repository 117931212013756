import * as React from 'react';
import { useLatest } from 'react-use';

type CallbackPayload = {
  catchContextMenuClick?: boolean;
  callback: (e: React.MouseEvent) => void;
};

const OutsideContext = React.createContext<{
  register: (fun: CallbackPayload) => void;
  unRegister: (fun: CallbackPayload) => void;
}>({
  register: () => {
    console.error('Outside context not found');
  },
  unRegister: () => {
    console.error('Outside context not found');
  },
});

type OutsideProviderProps = {
  children: (innerProps: {
    onClick: React.MouseEventHandler;
    onContextMenuCapture: React.MouseEventHandler;
  }) => React.ReactNode;
};

/**
 * Holds list of callbacks and provides outside click handler
 *
 * All registered callbacks receive the events captured by the click handler
 *
 * The click handler should be registered on a top level dom element covering the full viewport
 */
export const OutsideProvider: React.FC<OutsideProviderProps> = ({ children }) => {
  const { current: callbacks } = React.useRef<CallbackPayload[]>([]);

  const { contextValue, handleClickedOutside, handleClickedOutsideContextMenu } = React.useMemo(() => {
    const handleClickedOutside = (e: React.MouseEvent) => {
      for (const callback of callbacks) {
        callback.callback(e);
      }
    };
    const handleClickedOutsideContextMenu = (e: React.MouseEvent) => {
      for (const callback of callbacks) {
        if (callback.catchContextMenuClick) {
          callback.callback(e);
        }
      }
    };

    const contextValue = {
      register: (val: CallbackPayload) => callbacks.push(val),
      unRegister: (fun: CallbackPayload) => {
        const index = callbacks.indexOf(fun);

        // Callback does not exist.
        if (index === -1) {
          return;
        }

        callbacks.splice(index, 1);
      },
    };

    return { handleClickedOutside, handleClickedOutsideContextMenu, contextValue };
  }, [callbacks]);

  return (
    <OutsideContext.Provider value={contextValue}>
      {children({ onClick: handleClickedOutside, onContextMenuCapture: handleClickedOutsideContextMenu })}
    </OutsideContext.Provider>
  );
};

/**
 * This hook enables the distinction between inside and outside events (works with portals)
 *
 * It uses normal react events => correctly works with portals
 * The returned click handler must be attached to an element that is considered "inside".
 *
 * The given outside-callback is only called if a click the event did not bubble through the inside event but ended at a source.
 *
 * @returns a click handler to be attached to the "inside" element
 */
export function useClickedOutsideEvent<T extends CallbackPayload['callback']>(
  onClickedOutside: T,
  options: { disabled?: boolean; catchRightClick?: boolean } = {},
) {
  const { disabled = false, catchRightClick = false } = options;
  const { register, unRegister } = React.useContext(OutsideContext);
  const stableCallback = useLatest(onClickedOutside);

  const lastInside = React.useRef<null | React.MouseEvent>(null);

  const handleClickedOutside = React.useCallback(
    (e: React.MouseEvent) => {
      if (e === lastInside.current) {
        // ignore this event as it was triggered inside
      } else {
        stableCallback.current(e);
      }
      lastInside.current = null;
    },
    [stableCallback],
  );
  const handleClickedInside = (e: React.MouseEvent) => {
    lastInside.current = e;
  };

  React.useEffect(() => {
    if (disabled) {
      return; // noop
    }

    const payload: CallbackPayload = {
      callback: handleClickedOutside,
      catchContextMenuClick: catchRightClick,
    };

    register(payload);
    return () => unRegister(payload);
  }, [handleClickedOutside, disabled, catchRightClick]);

  return { handleClickedInside };
}
