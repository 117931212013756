import { ApolloClient, DocumentNode, useApolloClient } from '@apollo/client';
import { logDebug } from '@txt/core.tracking';
import { ErrorDisplay } from '@txt/core.ui/ErrorDisplay';
import { LoadingIndicator } from '@txt/core.ui/LoadingIndicator';
import { uniqueArray } from '@txt/core.utils/array';
import { extractFragmentTypeCondition } from '@txt/core.utils/graphQL';
import { createImmutableMap } from '@txt/core.utils/immutable';
import { cancelable, isErrorCanceled } from '@txt/core.utils/promise';
import Immutable from 'immutable';
import { chunk, isEqual } from 'lodash';
import * as React from 'react';
import { useCustomCompareEffect } from 'react-use';

/** Max items per network request. */
const BATCH_SIZE = 200;

type Options<Key extends string> = {
  fragmentName?: string;
  /** the fragment which fetches the entity info */
  fragment: DocumentNode;
  /**
   * the query to fetch the entities, it has to at least fetch alle the information
   * of the fragment. It needs to use a variable "ids" which will hold the ids
   * extracted with fetchKey
   */
  query: DocumentNode;

  idKey?: Key;

  /** you should set this prop if the variable name of the gql query differs from the default name :"ids" */
  variableName?: string;
  batchSize?: number;
};

type State<T> = {
  items: Immutable.Map<string, T>;
  loading: boolean;
  error: any | null;
};

/**
 * Loads batched instances via graphql.
 * Only items which are not yet in the apollo cache are loaded.
 *
 * CAUTION: This loader returns partial results.
 */
export function createUseBatchedEntitiesById<T extends { [key in Key]: txtureId }, Key extends string = 'txtureId'>({
  fragmentName,
  fragment,
  query,
  idKey = 'txtureId' as Key,
  variableName = 'ids',
  batchSize = BATCH_SIZE,
}: Options<Key>) {
  /**
   * @param ids the txtureIds of an gql entity
   */
  return (ids: txtureId[]) => {
    const client = useApolloClient();
    const lookup = React.useCallback(
      (ids: txtureId[]) => lookupApolloCache<T, Key>(client, fragment, fragmentName, idKey, ids),
      [fragment, fragmentName, idKey, client],
    );

    const [state, set] = React.useState<State<T>>(() => {
      const { hits, misses } = lookup(ids);

      return {
        items: createImmutableMap(hits, (i) => i[idKey]),
        loading: misses.length > 0,
        error: null,
      };
    });

    useCustomCompareEffect(
      (): void | (() => void) => {
        const { hits, misses } = lookup(ids);
        const items = createImmutableMap(hits, (i) => i[idKey]);

        if (misses.length === 0) {
          set((currentState) =>
            currentState.items.equals(items) ? currentState : { items, loading: false, error: null },
          );
        } else {
          set((currentState) =>
            currentState.items.equals(items) && currentState.loading === true
              ? currentState // already in loading state
              : { items, loading: true, error: null },
          );

          const promises = chunk(misses, batchSize).map((idBatch) =>
            cancelable(client.query({ query, variables: { [variableName]: idBatch } })),
          );

          async function fetch() {
            try {
              const result = await Promise.all(promises);

              const failedRequests = result.filter((r) => r.error);
              if (failedRequests.length > 0) {
                console.error(failedRequests);
                set({
                  items,
                  loading: false,
                  error: failedRequests[0].error!,
                });
              }

              const { hits, misses } = lookup(ids);
              if (misses.length > 0) {
                logDebug('batchedLoader: could not resolve', misses);
              }

              set({
                items: createImmutableMap(hits, (i) => i[idKey]),
                loading: false,
                error: null,
              });
            } catch (error) {
              if (!isErrorCanceled(error)) {
                set((currentState) => ({
                  ...currentState,
                  loading: false,
                  error,
                }));
              }
            }
          }

          fetch();

          return () => promises.forEach((p) => p.cancel());
        }
      },
      [ids],
      ([prevIds], [nextIds]) => isEqual(new Set(prevIds), new Set(nextIds)),
    );

    const { loading, error, items } = state;

    const loadingOrErrCmp = error ? <ErrorDisplay>{error}</ErrorDisplay> : loading ? <LoadingIndicator /> : null;

    return {
      loading,
      error,
      items,
      loadingOrErrCmp,
    };
  };
}

function lookupApolloCache<T extends { [key in Key]: txtureId }, Key extends string>(
  client: ApolloClient<object>,
  fragment: DocumentNode,
  fragmentName: string | undefined,
  key: string,
  ids: txtureId[],
) {
  const typename = extractFragmentTypeCondition(fragment, fragmentName);
  const hits: T[] = [];
  const misses: txtureId[] = [];

  for (const id of uniqueArray(ids)) {
    const cacheId = client.cache.identify({
      __typename: typename,
      [key]: id,
    });

    const item = client.readFragment<T>({
      id: cacheId,
      fragment,
      fragmentName,
    });

    if (item === null) {
      misses.push(id);
    } else {
      hits.push(item);
    }
  }

  return {
    misses,
    hits,
  };
}
