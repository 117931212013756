/** Pushes a google analytics tracking event */
export function pushGoogleTrackingEvent(event: { event: string } & { [key: string]: string | undefined }) {
  const w = window as any;
  if (w) {
    if (!w.dataLayer) {
      w.dataLayer = [];
    }
    w.dataLayer.push(event);
  }
}
