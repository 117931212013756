import * as React from 'react';

type Setter = (title?: string) => void;
const initialValue: Setter = (_) => undefined;

export const DocumentTitleContext = React.createContext<Setter>(initialValue);

/**
 * Sets the document title, based on the 'useTitle' hook from react-use, and a special 'setter' defined in the 'DocumentTitleContext.Provider'.
 */
export const useDocumentTitle = (title?: string) => React.useContext(DocumentTitleContext)(title);
