import * as Sentry from '@sentry/browser';
import { getBuildVersion, getEnvironment, registerDebugModeHandler } from '@txt/core.env';
import { initPageTracker } from '@txt/core.tracking';
import ReactDOM from 'react-dom/client';
import { App } from './App';

registerDebugModeHandler();

if (getEnvironment() === 'production') {
  Sentry.init({
    dsn: 'https://9b80fafe3ebc40d69735c32409885cc3@o120926.ingest.sentry.io/5464823',
    environment: getEnvironment(),
    release: getBuildVersion(),
  });

  initPageTracker({
    mixpanelTrackerId: '3ed9a951e429cbf4210e911e42ca75e1',
  });
}

const wrapEl = document.getElementById('root');
if (wrapEl) {
  const root = ReactDOM.createRoot(wrapEl);
  root.render(<App />);
} else {
  throw new Error(`#root wrapper element is missing.`);
}
