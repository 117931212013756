import { compose, system } from 'styled-system';
import { type ThemeColors } from '../theme';

export { ThemeColors };

export type Colors = {
  color?: ThemeColors;
  background?: ThemeColors;
  bg?: ThemeColors;
  border?: ThemeColors;
};

const color = system({
  color: {
    property: 'color',
    scale: 'palette',
  },
});

const background = system({
  background: {
    property: 'backgroundColor',
    scale: 'palette',
  },
});

const bg = system({
  bg: {
    property: 'backgroundColor',
    scale: 'palette',
  },
});

const border = system({
  border: {
    property: 'borderColor',
    scale: 'palette',
  },
});

export const colors = compose(color, background, bg, border);
