import styled from '@emotion/styled';
import { FlexboxProps, HeightProps, WidthProps, flexbox, height, system, width } from 'styled-system';
import { Box } from './Box';
import { Theme } from './theme';

type SpaceKeys = keyof Theme['space'];
interface GapProps {
  gap?: SpaceKeys | `${SpaceKeys}`;
}

const gap = system({
  gap: {
    property: 'gap',
    scale: 'space',
  },
});

export interface FlexProps extends FlexboxProps, GapProps, WidthProps, HeightProps {}

export const Flex = styled(Box)<FlexProps>({ display: 'flex' }, flexbox, gap, width, height);

export const FlexCentered = styled(Flex)<FlexProps>({ alignItems: 'center', justifyContent: 'center' });
