import * as React from 'react';

/**
 * A React state which is initialized with the provided value.
 * If the provided value is changed, the state is reset to this value.
 */
export function useDerivedState<T>(propVal: T) {
  const state = React.useState<T>(propVal);

  React.useEffect(() => {
    state[1](propVal);
  }, [propVal]);

  return state;
}
