/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query AccountQuery {\n    me {\n      email\n\n      company {\n        id\n        name\n\n        subscription {\n          ... on TrialSubscription {\n            until\n          }\n          ... on FreeSubscription {\n            limits {\n              maxProjectsPerUser\n            }\n          }\n          ... on FreemiumSubscription {\n            limits {\n              maxProjectsPerUser\n              maxSolutionsPerProject\n            }\n          }\n          ... on PaySubscription {\n            subscriptionId\n          }\n        }\n      }\n    }\n  }\n": types.AccountQueryDocument,
    "\n  query AccountTaxInfoQuery {\n    me {\n      email\n      company {\n        id\n        hasTaxId\n      }\n    }\n  }\n": types.AccountTaxInfoQueryDocument,
    "\n  mutation CompanyTaxIdMutation($taxId: TaxIdCreateInput!) {\n    addTaxId(taxId: $taxId) {\n      success\n      message\n      code\n    }\n  }\n": types.CompanyTaxIdMutationDocument,
    "\n  fragment CompanySubscriptionProperties on Company {\n    id\n\n    canSubscribeToFreeTrial\n\n    subscription {\n      limits {\n        maxProjectsPerUser\n        maxSolutionsPerProject\n        maxServicesPerSolution\n        canAccessReservedPricing\n        canAccessCertification\n        canGenAlternativeSolutions\n        canExportSolution\n      }\n\n      ... on TrialSubscription {\n        until\n      }\n\n      ... on PaySubscription {\n        subscriptionId\n      }\n    }\n  }\n": types.CompanySubscriptionPropertiesFragmentDoc,
    "\n  query SubscriptionQuery {\n    me {\n      email\n\n      company {\n        ...CompanySubscriptionProperties\n      }\n    }\n\n    billingConfig {\n      stripeApiKey\n    }\n  }\n": types.SubscriptionQueryDocument,
    "\n  mutation SubscribeFreeTrial {\n    subscribeFreeTrial {\n      code\n      success\n      message\n      company {\n        ...CompanySubscriptionProperties\n      }\n    }\n  }\n": types.SubscribeFreeTrialDocument,
    "\n  mutation DowngradeToFreemium {\n    downgradeToFreemium {\n      code\n      success\n      message\n      company {\n        ...CompanySubscriptionProperties\n      }\n    }\n  }\n": types.DowngradeToFreemiumDocument,
    "\n  query MeQuery {\n    me {\n      id\n      email\n      firstname\n      lastname\n      settings {\n        currency\n      }\n    }\n  }\n": types.MeQueryDocument,
    "\n  query UserSettingsQuery {\n    me {\n      email\n      settings {\n        hasWatchedIntroVideo\n        betaShowBomView\n        currency\n      }\n    }\n  }\n": types.UserSettingsQueryDocument,
    "\n  mutation UpdateUserSettingsMutation($settings: UserSettingsInput!) {\n    updateUserSettings(settings: $settings) {\n      success\n      user {\n        email\n        settings {\n          hasWatchedIntroVideo\n          currency\n        }\n      }\n    }\n  }\n": types.UpdateUserSettingsMutationDocument,
    "\n  query CloudProductNameQuery($productId: String!) {\n    cloudProduct(txtureId: $productId) {\n      txtureId\n      name\n      provider {\n        name\n      }\n    }\n  }\n": types.CloudProductNameQueryDocument,
    "\n  query ProductInstancePathQuery($instanceId: String!) {\n    productInstanceById(txtureId: $instanceId) {\n      id\n      txtureId\n      name\n      product {\n        txtureId\n        name\n        provider {\n          name\n        }\n      }\n    }\n  }\n": types.ProductInstancePathQueryDocument,
    "\n  query DataCenterPathQuery($dataCenterId: ID!) {\n    dataCenterById(dataCenterId: $dataCenterId) {\n      txtureId\n      name\n      provider {\n        txtureId\n        name\n      }\n    }\n  }\n": types.DataCenterPathQueryDocument,
    "\n  query DataCentersQuery {\n    dataCenters {\n      txtureId\n      name\n      key\n      location {\n        name\n        coordinates {\n          lat\n          long\n        }\n        path\n      }\n      provider {\n        txtureId\n        name\n        imageUrl\n        iconImageUrl\n      }\n    }\n  }\n": types.DataCentersQueryDocument,
    "\n  query CloudProvidersStatsQuery {\n    cloudProviderStats {\n      numberOfProviders\n      numberOfProducts\n      numberOfProductInstances\n      numberOfDataCenters\n    }\n  }\n": types.CloudProvidersStatsQueryDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AccountQuery {\n    me {\n      email\n\n      company {\n        id\n        name\n\n        subscription {\n          ... on TrialSubscription {\n            until\n          }\n          ... on FreeSubscription {\n            limits {\n              maxProjectsPerUser\n            }\n          }\n          ... on FreemiumSubscription {\n            limits {\n              maxProjectsPerUser\n              maxSolutionsPerProject\n            }\n          }\n          ... on PaySubscription {\n            subscriptionId\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AccountQuery {\n    me {\n      email\n\n      company {\n        id\n        name\n\n        subscription {\n          ... on TrialSubscription {\n            until\n          }\n          ... on FreeSubscription {\n            limits {\n              maxProjectsPerUser\n            }\n          }\n          ... on FreemiumSubscription {\n            limits {\n              maxProjectsPerUser\n              maxSolutionsPerProject\n            }\n          }\n          ... on PaySubscription {\n            subscriptionId\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AccountTaxInfoQuery {\n    me {\n      email\n      company {\n        id\n        hasTaxId\n      }\n    }\n  }\n"): (typeof documents)["\n  query AccountTaxInfoQuery {\n    me {\n      email\n      company {\n        id\n        hasTaxId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CompanyTaxIdMutation($taxId: TaxIdCreateInput!) {\n    addTaxId(taxId: $taxId) {\n      success\n      message\n      code\n    }\n  }\n"): (typeof documents)["\n  mutation CompanyTaxIdMutation($taxId: TaxIdCreateInput!) {\n    addTaxId(taxId: $taxId) {\n      success\n      message\n      code\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CompanySubscriptionProperties on Company {\n    id\n\n    canSubscribeToFreeTrial\n\n    subscription {\n      limits {\n        maxProjectsPerUser\n        maxSolutionsPerProject\n        maxServicesPerSolution\n        canAccessReservedPricing\n        canAccessCertification\n        canGenAlternativeSolutions\n        canExportSolution\n      }\n\n      ... on TrialSubscription {\n        until\n      }\n\n      ... on PaySubscription {\n        subscriptionId\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CompanySubscriptionProperties on Company {\n    id\n\n    canSubscribeToFreeTrial\n\n    subscription {\n      limits {\n        maxProjectsPerUser\n        maxSolutionsPerProject\n        maxServicesPerSolution\n        canAccessReservedPricing\n        canAccessCertification\n        canGenAlternativeSolutions\n        canExportSolution\n      }\n\n      ... on TrialSubscription {\n        until\n      }\n\n      ... on PaySubscription {\n        subscriptionId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SubscriptionQuery {\n    me {\n      email\n\n      company {\n        ...CompanySubscriptionProperties\n      }\n    }\n\n    billingConfig {\n      stripeApiKey\n    }\n  }\n"): (typeof documents)["\n  query SubscriptionQuery {\n    me {\n      email\n\n      company {\n        ...CompanySubscriptionProperties\n      }\n    }\n\n    billingConfig {\n      stripeApiKey\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SubscribeFreeTrial {\n    subscribeFreeTrial {\n      code\n      success\n      message\n      company {\n        ...CompanySubscriptionProperties\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SubscribeFreeTrial {\n    subscribeFreeTrial {\n      code\n      success\n      message\n      company {\n        ...CompanySubscriptionProperties\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DowngradeToFreemium {\n    downgradeToFreemium {\n      code\n      success\n      message\n      company {\n        ...CompanySubscriptionProperties\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DowngradeToFreemium {\n    downgradeToFreemium {\n      code\n      success\n      message\n      company {\n        ...CompanySubscriptionProperties\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MeQuery {\n    me {\n      id\n      email\n      firstname\n      lastname\n      settings {\n        currency\n      }\n    }\n  }\n"): (typeof documents)["\n  query MeQuery {\n    me {\n      id\n      email\n      firstname\n      lastname\n      settings {\n        currency\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserSettingsQuery {\n    me {\n      email\n      settings {\n        hasWatchedIntroVideo\n        betaShowBomView\n        currency\n      }\n    }\n  }\n"): (typeof documents)["\n  query UserSettingsQuery {\n    me {\n      email\n      settings {\n        hasWatchedIntroVideo\n        betaShowBomView\n        currency\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUserSettingsMutation($settings: UserSettingsInput!) {\n    updateUserSettings(settings: $settings) {\n      success\n      user {\n        email\n        settings {\n          hasWatchedIntroVideo\n          currency\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUserSettingsMutation($settings: UserSettingsInput!) {\n    updateUserSettings(settings: $settings) {\n      success\n      user {\n        email\n        settings {\n          hasWatchedIntroVideo\n          currency\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CloudProductNameQuery($productId: String!) {\n    cloudProduct(txtureId: $productId) {\n      txtureId\n      name\n      provider {\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query CloudProductNameQuery($productId: String!) {\n    cloudProduct(txtureId: $productId) {\n      txtureId\n      name\n      provider {\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ProductInstancePathQuery($instanceId: String!) {\n    productInstanceById(txtureId: $instanceId) {\n      id\n      txtureId\n      name\n      product {\n        txtureId\n        name\n        provider {\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ProductInstancePathQuery($instanceId: String!) {\n    productInstanceById(txtureId: $instanceId) {\n      id\n      txtureId\n      name\n      product {\n        txtureId\n        name\n        provider {\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query DataCenterPathQuery($dataCenterId: ID!) {\n    dataCenterById(dataCenterId: $dataCenterId) {\n      txtureId\n      name\n      provider {\n        txtureId\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query DataCenterPathQuery($dataCenterId: ID!) {\n    dataCenterById(dataCenterId: $dataCenterId) {\n      txtureId\n      name\n      provider {\n        txtureId\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query DataCentersQuery {\n    dataCenters {\n      txtureId\n      name\n      key\n      location {\n        name\n        coordinates {\n          lat\n          long\n        }\n        path\n      }\n      provider {\n        txtureId\n        name\n        imageUrl\n        iconImageUrl\n      }\n    }\n  }\n"): (typeof documents)["\n  query DataCentersQuery {\n    dataCenters {\n      txtureId\n      name\n      key\n      location {\n        name\n        coordinates {\n          lat\n          long\n        }\n        path\n      }\n      provider {\n        txtureId\n        name\n        imageUrl\n        iconImageUrl\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CloudProvidersStatsQuery {\n    cloudProviderStats {\n      numberOfProviders\n      numberOfProducts\n      numberOfProductInstances\n      numberOfDataCenters\n    }\n  }\n"): (typeof documents)["\n  query CloudProvidersStatsQuery {\n    cloudProviderStats {\n      numberOfProviders\n      numberOfProducts\n      numberOfProductInstances\n      numberOfDataCenters\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;