// candidate for global types
export type OmitNested<T, K extends string | number | symbol> =
  T extends Array<infer U>
    ? OmitNested<U, K>[]
    : T extends object
      ? { [Key in Exclude<keyof T, K>]: OmitNested<T[Key], K> }
      : T;

/**
 * Clones an object and removes all __typename properties from an object.
 */
export function removeTypeFields<T>(obj: T): OmitNested<T, '__typename'> {
  if (Array.isArray(obj)) {
    return obj.map(removeTypeFields) as OmitNested<T, '__typename'>;
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([key]) => key !== '__typename')
        .map(([key, val]) => [key, removeTypeFields(val)]),
    ) as OmitNested<T, '__typename'>;
  }

  return obj as OmitNested<T, '__typename'>;
}
