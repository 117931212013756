import * as Sentry from '@sentry/browser';
import { getEnvironment } from '@txt/core.env';
import * as React from 'react';

const env = getEnvironment();

/** Log an error to sentry. */
export function logError(ex: Error | ErrorEvent | string, ...args: any[]) {
  console.error(ex, ...args);
  reportSentryError('error', ex, ...args);
}

/** Log a warning to sentry. */
export function logWarning(ex: Error | ErrorEvent | string, ...args: any[]) {
  console.warn(ex, ...args);
  reportSentryError('warning', ex, ...args);
}

function reportSentryError(severity: Sentry.SeverityLevel, ex: Error | ErrorEvent | string, ...args: any[]) {
  if (env === 'development') {
    return;
  }

  if (typeof ex === 'object' && ex.message) {
    // "ex" is an rest api error object (can not be reported as exception with raven)
    Sentry.withScope((scope) => {
      scope.setExtras({ ex, args });
      Sentry.captureMessage(ex.message, severity);
    });
  } else {
    Sentry.withScope((scope) => {
      scope.setLevel(severity);
      scope.setExtras({ stacktrace: true, args });
      Sentry.captureException(ex);
    });
  }
}

/** Logs a debug message to the console in development mode. In production nothing is logged. */
export function logDebug(ex: Error | ErrorEvent | string, ...args: any[]) {
  if (env !== 'development' && !window.TXTURE_CONFIG?.txtureModeEnabled) {
    return;
  }
  console.log(ex, ...args);
}

export function useLogDebugObject(msg: string, logObject: Object, options: { deepEqual?: boolean } = {}) {
  const shouldLog = env === 'development';
  const deps = options.deepEqual && !shouldLog ? [JSON.stringify(logObject)] : Object.values(logObject);
  React.useMemo(() => {
    if (!shouldLog) {
      return;
    }

    console.log(msg, logObject);
  }, deps);
}
