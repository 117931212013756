import { createUseQuery } from '@txt/core.hooks';
import { graphql } from '../generated-types';

export const MEQuery = graphql(`
  query MeQuery {
    me {
      id
      email
      firstname
      lastname
      settings {
        currency
      }
    }
  }
`);
export const useMeQuery = createUseQuery(MEQuery);
