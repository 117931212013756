import React from 'react';
import { assertIsDefined } from '../assertIsDefined';

type ANotB<A, B> = A & { [K in keyof B]?: never };
type Distinct<A, B> = ANotB<A, B> | ANotB<B, A>;

type OptionsControlled<T> = {
  value: T;
  setValue: (value: T) => void;
};

type OptionsUncontrolled<T> = {
  initialValue: T;
};

type Options<T> = Distinct<OptionsControlled<T>, OptionsUncontrolled<T>> & {
  /* optional handler for side effects on value change. */
  onChange?: (value: T) => void;
};

export function useControllableState<T>({ onChange, ...options }: Options<T>) {
  const setValueRef = React.useRef<(value: T) => void>();
  setValueRef.current = options.setValue;

  const [uncontrolledValue, setUncontrolledValue] = React.useState(options.initialValue);

  const isControlled = 'value' in options;

  const value = isControlled ? options.value : uncontrolledValue;

  const setValue = React.useCallback((value: T) => {
    if (isControlled) {
      assertIsDefined(setValueRef.current);
      setValueRef.current(value);
    } else {
      setUncontrolledValue(value);
    }

    if (onChange) {
      onChange(value);
    }
  }, []);

  return [value as T, setValue] as const;
}
