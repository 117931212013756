import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Theme, ThemeColors, theme } from '@txt/core.styles';
import { mergeRefs } from '@txt/core.utils/mergeRefs';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { variant } from 'styled-system';
import { Icon } from '../Icon';
import { Tooltip, TooltipPlacement } from '../tooltip';

type Color = 'primary' | 'red';
type Variant = 'contained' | 'inverted' | 'inverted-grey' | 'text' | 'text-grey' | 'outlined' | 'outlined-grey';
type Size = 'xs' | 's' | 'm' | 'l';

type ButtonColors = {
  bg: ThemeColors;
  border?: ThemeColors;
  label: ThemeColors;
  icon?: ThemeColors;
};

type ButtonStatesConfig = {
  default: ButtonColors;
  disabled: ButtonColors;
  hover: ButtonColors;
  focus: ButtonColors;
  active: ButtonColors;
};

const createVariant = (tokens: ButtonStatesConfig) => (t: Theme) => {
  const color = (color: ThemeColors) => theme(`palette.${color}` as any)({ theme: t }) ?? color;

  const getVariables = (token: keyof ButtonStatesConfig) => css`
    --label-color: ${color(tokens[token].label)};
    --bg-color: ${color(tokens[token].bg)};
    --border-color: ${color(tokens[token].border ?? tokens[token].bg)};
    --icon-color: ${color(tokens[token].icon ?? tokens[token].label)};
  `;

  return css`
    ${getVariables('default')};

    :disabled,
    &[data-disabled='true'] {
      ${getVariables('disabled')};
    }

    &:not([data-disabled='true']) {
      &:hover,
      &[data-hover='true'] {
        ${getVariables('hover')};
      }

      &:focus,
      &[data-focus='true'] {
        ${getVariables('focus')};
      }

      &:active,
      &[data-active='true'] {
        ${getVariables('active')};
      }
    }
  `;
};

const variants = (color: Color) =>
  variant({
    prop: 'variant',
    variants: {
      contained: createVariant({
        default: {
          bg: `${color}.600`,
          border: `${color}.600`,
          label: 'white',
        },
        disabled: {
          bg: 'grey.300',
          label: 'grey.100',
        },
        hover: {
          bg: `${color}.500`,
          label: 'white',
        },
        focus: {
          bg: `${color}.500`,
          label: 'white',
          border: `${color}.200`,
        },
        active: {
          bg: `${color}.700`,
          label: 'white',
        },
      }),
      inverted: createVariant({
        default: {
          bg: 'white',
          border: 'white',
          label: `${color}.700`,
          icon: `${color}.600`,
        },
        disabled: {
          bg: 'white',
          label: 'grey.100',
        },
        hover: {
          bg: `${color}.50`,
          label: `${color}.700`,
          icon: `${color}.600`,
        },
        focus: {
          bg: `${color}.100`,
          label: `${color}.700`,
          border: `${color}.200`,
          icon: `${color}.600`,
        },
        active: {
          bg: `${color}.200`,
          label: `${color}.700`,
          icon: `${color}.600`,
        },
      }),
      'inverted-grey': createVariant({
        default: {
          bg: 'white',
          border: 'white',
          label: 'grey.700',
        },
        disabled: {
          bg: 'white',
          label: 'grey.300',
        },
        hover: {
          bg: 'grey.100',
          label: 'grey.900',
        },
        focus: {
          bg: 'grey.200',
          label: 'grey.900',
          border: `${color}.200`,
        },
        active: {
          bg: 'grey.200',
          label: 'grey.900',
        },
      }),
      text: createVariant({
        default: {
          bg: 'transparent',
          label: `${color}.700`,
          icon: `${color}.600`,
        },
        disabled: {
          bg: 'transparent',
          label: 'grey.300',
        },
        hover: {
          bg: 'transparent',
          label: `${color}.500`,
        },
        focus: {
          bg: 'transparent',
          label: `${color}.500`,
          border: `${color}.200`,
        },
        active: {
          bg: 'grey.50',
          label: `${color}.700`,
        },
      }),
      'text-grey': createVariant({
        default: {
          bg: 'transparent',
          label: 'grey.700',
          icon: 'grey.600',
        },
        disabled: {
          bg: 'transparent',
          label: 'grey.300',
        },
        hover: {
          bg: 'transparent',
          label: 'grey.900',
          icon: 'grey.800',
        },
        focus: {
          bg: 'grey.50',
          label: 'grey.900',
          icon: 'grey.800',
          border: `${color}.200`,
        },
        active: {
          bg: 'grey.50',
          label: `${color}.900`,
          icon: 'grey.800',
        },
      }),
      outlined: createVariant({
        default: {
          bg: 'transparent',
          border: `${color}.600`,
          label: `${color}.700`,
        },
        disabled: {
          bg: 'transparent',
          label: 'grey.300',
          border: 'grey.300',
        },
        hover: {
          bg: 'transparent',
          label: `${color}.500`,
          border: `${color}.600`,
        },
        focus: {
          bg: 'transparent',
          label: `${color}.500`,
          border: `${color}.300`,
        },
        active: {
          bg: 'white',
          label: `${color}.500`,
          border: `${color}.600`,
        },
      }),
      'outlined-grey': createVariant({
        default: {
          bg: 'transparent',
          label: 'grey.700',
          border: 'grey.700',
        },
        disabled: {
          bg: 'transparent',
          label: 'grey.300',
          border: 'grey.300',
        },
        hover: {
          bg: 'transparent',
          label: 'grey.500',
          border: 'grey.500',
        },
        focus: {
          bg: 'transparent',
          label: 'grey.500',
          border: `${color}.300`,
        },
        active: {
          bg: 'white',
          label: 'grey.500',
          border: 'grey.500',
        },
      }),
    },
  });

const sizes = variant({
  prop: 'size',
  variants: {
    xs: {
      height: '26px',
      fontSize: (theme: Theme) => theme.typography.fontSizes.sm,
      padding: (theme: Theme) => `0 ${theme.space[2]}`,
    },
    s: {
      height: '36px',
      fontSize: (theme: Theme) => theme.typography.fontSizes.md,
      padding: (theme: Theme) => `0 ${theme.space[3]}`,
    },
    m: {
      height: '46px',
      fontSize: (theme: Theme) => theme.typography.fontSizes.xl,
      padding: (theme: Theme) => `0 ${theme.space[3]}`,
    },
    l: {
      height: '56px',
      fontSize: (theme: Theme) => theme.typography.fontSizes.xl3,
      padding: (theme: Theme) => `0 ${theme.space[4]}`,
    },
  },
});

const transition = css`
  transition: all 150ms ease-in;
`;

// Reset some global styles that are interfering with the button
// This is a hack and should be removed when the global styles are fixed
const hack = css`
  .fa {
    font-size: 1em;
  }

  span + .fa,
  .fa + span {
    padding-left: 0;
  }
`;

const StyledButton = styled.button<{ variant: Variant; size: Size; color: Color }>`
  all: unset;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[2]};
  max-width: max-content;
  border: 2px solid;
  border-radius: 2px;
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  line-height: ${({ theme }) => theme.typography.lineHeight.normal};
  ${transition}
  ${(p) => variants(p.color)}

  &:hover {
    // disable text-decoration for link buttons
    text-decoration: none;
  }

  background-color: var(--bg-color);
  color: var(--label-color);
  border-color: var(--bg-color);

  &:before {
    content: ' ';
    position: absolute;
    inset: -2px;
    border: 1px solid var(--border-color);
    border-radius: 2px;
    ${transition}
    pointer-events: none;
  }

  &:disabled,
  &[data-disabled='true'] {
    cursor: default;
  }

  &:not([data-disabled='true']) {
    &:focus,
    &[data-focus='true'] {
      border-color: var(--border-color);
    }
  }

  ${sizes}
  ${hack}
`;

const ButtonIcon = styled(Icon)`
  color: var(--icon-color);
  ${transition}
`;

const linkButtonStyles = css`
  &:focus,
  &[data-focus='true'],
  &:active,
  &[data-active='true'] {
    text-decoration: underline;
  }
`;

type CommonProps = {
  variant: Variant;
  size?: Size;
  color?: Color;
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  tabIndex?: number;
  title?: string;
  autoFocus?: boolean;
  'data-disabled'?: boolean;
  'data-hover'?: boolean;
  'data-focus'?: boolean;
  'data-active'?: boolean;
};

type ButtonProps = CommonProps & {
  type?: React.JSX.IntrinsicElements['button']['type'];
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onPointerDown?: (e: React.PointerEvent<HTMLButtonElement>) => void;
  tooltip?: React.ReactNode;
  tooltipPlace?: TooltipPlacement;
  /** Id to access button in test cases */
  testAnchorId?: string;
  isActive?: boolean;
};

export const Button = Object.assign(
  React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
      {
        type = 'button',
        size = 's',
        color = 'primary',
        disabled,
        tooltip,
        tooltipPlace,
        testAnchorId,
        onClick,
        isActive = false,
        ...rest
      },
      ref,
    ) => {
      function handleDisabled(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }

      return (
        <Tooltip content={tooltip} place={tooltipPlace}>
          {(registerTooltip) => (
            <StyledButton
              data-test={testAnchorId}
              data-active={isActive}
              type={type}
              size={size}
              color={color}
              {...rest}
              {...(disabled ? { 'data-disabled': true, onClick: handleDisabled } : { onClick })}
              {...(tooltip ? { ...registerTooltip, ref: mergeRefs(ref, registerTooltip.ref) } : { ref })}
            />
          )}
        </Tooltip>
      );
    },
  ),
  { Icon: ButtonIcon },
);

type ExternalLinkButtonProps = CommonProps & {
  href: string;
  target?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

const StyledExternalLinkButton = styled(StyledButton.withComponent('a'))(linkButtonStyles);

export const ExternalLinkButton = Object.assign(
  ({ size = 's', color = 'primary', ...props }: ExternalLinkButtonProps) => (
    <StyledExternalLinkButton size={size} color={color} {...props} />
  ),
  { Icon: ButtonIcon },
);

type LinkButtonProps = CommonProps & React.ComponentProps<typeof Link>;

const StyledLinkButton = styled(StyledButton.withComponent(Link))(linkButtonStyles);

export const LinkButton = Object.assign(
  ({ size = 's', color = 'primary', ...props }: LinkButtonProps) => (
    <StyledLinkButton size={size} color={color} {...props} />
  ),
  {
    Icon: ButtonIcon,
  },
);
