import { css, Global } from '@emotion/react';
import * as React from 'react';

export const GlobalInsiderStyles: React.FC = () => {
  return (
    <Global
      styles={css`
        body {
          background: #e9ebed;
        }

        body.ReactModal__Body--open {
          overflow: hidden;
        }

        #root {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        }
      `}
    />
  );
};
