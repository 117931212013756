import { valueNotNullable } from './filter';
import { Comparator } from './sorting';

export { default as arrayMoveImmutable } from 'array-move';

/** Returns the sum of all values of the given array. */
export function arraySum(arr: number[]): number {
  return arr.reduce((acc, v) => acc + v, 0);
}

/**
 * Sums up all number values of an object array.
 * @returns object with sums.
 */
export function arrayNestedSum<T extends Record<string, unknown>>(arr: T[]): Record<keyof T, number> {
  const output = {} as Record<keyof T, number>;

  for (let index = 0; index < arr.length; index++) {
    for (let [key, value] of Object.entries(arr[index])) {
      if (typeof value === 'number') {
        output[key] = (output[key] ?? 0) + value;
      }
    }
  }

  return output;
}

/** Returns the average of all values of the given array.
 *
 * Returns 0 for empty arrays
 */
export function arrayAvg(arr: number[]): number {
  if (arr.length === 0) {
    return 0;
  }
  return arraySum(arr) / arr.length;
}

export function uniqueArray<T>(items: Iterable<T>): T[] {
  return [...new Set(items)];
}

/**
 * Splits an array into two sub-arrays based on the split function.
 */
export function splitArray<T>(items: Iterable<T>, isPartOfA: (item: T) => boolean): [a: T[], b: T[]] {
  const a: T[] = [];
  const b: T[] = [];

  for (const item of items) {
    if (isPartOfA(item)) {
      a.push(item);
    } else {
      b.push(item);
    }
  }

  return [a, b];
}

/**
 * Gets the top X entries from the array
 */
export function arrayHead<T>(num: number, items: T[]): T[] {
  return items.filter((_, i) => i < num);
}

/**
 * Gets the first element iff it is the only element
 */
export function arrayOnly<T extends any[]>(items: T): GetArrayElementType<T> | undefined {
  if (items.length === 1) {
    return items[0];
  }
  return undefined;
}

/**
 * Returns a new array with called `updater` on matching elements.
 *
 * If no element was found and a `defaultVal` was given, a new element is pushed.
 */
export function updateArrayItem<T>(arr: T[], find: (val: T) => boolean, updater: (val: T) => T, defaultVal?: T) {
  let hasMatch = false;
  const newItems = arr.map((el) => {
    if (find(el)) {
      hasMatch = true;
      return updater(el);
    }
    return el;
  });

  if (!hasMatch && defaultVal) {
    newItems.push(defaultVal);
  }

  return newItems;
}

/**
 * Gets the first element (if any)
 *
 * explicitly types the missing case as null
 */
export function arrayFirst<T>(arr: T[]): T | null {
  return arr[0] ?? null;
}

/**
 * Gets the last element (if any)
 */
export function arrayTail<T>(arr: T[]): T | undefined {
  return arr.at(arr.length - 1);
}

/** Gets the first non nullable value */
export function arrayCoalesce<T>(arr: T[]): T | null {
  return arrayFirst(arr.filter(valueNotNullable));
}

/**
 * Picks the properties inside the object array by the given keys.
 */
export function arrayPickNestedKeys<T extends Record<string, unknown>, K extends keyof T>(
  arr: T[],
  keys: string[],
): { [key in K]: T[key] }[] {
  return arr.map((x) => ({
    ...keys.reduce(
      (prevKey, currKey) => ({
        ...prevKey,
        [currKey]: x[currKey],
      }),
      {} as T,
    ),
  }));
}

/**
 * Pushes a value N times into the array
 */
export function pushNTimes<T>(items: T[], value: T | T[], times: number) {
  for (let i = 0; i < times; i++) {
    if (Array.isArray(value)) {
      items.push(...value);
    } else {
      items.push(value);
    }
  }
  return items;
}

/** creates an array of numbers within the given start and end range and the given increment */
export function range(start: number, end: number, increment: number = 1) {
  const res: number[] = [];
  for (let i = start; i <= end; i += increment) {
    res.push(i);
  }
  return res;
}

type AtLeastOne<T> = [T, ...T[]];

/**
 * This function gets a union type (as type param) & every value of a union type
 * It will check if every key of the union is passed as a param
 * - if any is missing it will show an error
 * - if any is too much (not in the union type) it will show an error
 * - if all are matching it will return the array with all values
 */
export const unionTypeToExhaustiveArray =
  <T>() =>
  <L extends AtLeastOne<T>>(
    ...x: L extends any ? (Exclude<T, L[number]> extends never ? L : Exclude<T, L[number]>[]) : never
  ) =>
    x;

export const sortedPush = <T extends any>(array: T[], newElement: T, comparator: Comparator<T>) => {
  let i = 0;
  for (; i < array.length; i++) {
    const el = array[i];

    if (comparator(newElement, el) < 0) {
      break;
    }
  }

  array.splice(i, 0, newElement);
  return array;
};
