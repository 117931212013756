import { NumberFormatOptions } from '@formatjs/ecma402-abstract';
import * as React from 'react';
import { CustomFormatConfig, FormatNumberOptions, FormattedNumber as FormattedNumberIntl } from 'react-intl';
import { getStaticIntl, getStaticIntlOptions, SupportedNumberFormats } from '../IntlProvider';

type Props = {
  value: number;
  className?: string;
} & NumberFormatOptions &
  CustomFormatConfig;

export const FormattedNumber: React.FC<Props> = ({ className, ...i18nProps }) => (
  <span className={className}>
    <FormattedNumberIntl {...i18nProps} />
  </span>
);

/** String only number renderer. */
export function formatNumber(value: number, opts?: FormatNumberOptions) {
  return getStaticIntl().formatNumber(value, opts);
}

export function parseNumberFromLocaleString(
  value: string,
  numberFormat: SupportedNumberFormats = getStaticIntlOptions().numberFormat,
): number {
  switch (numberFormat) {
    case '1,000.00':
      return Number.parseFloat(value.replace(/,|\s/g, ''));
    case '1.000,00':
    case '1 000,00':
      return Number.parseFloat(
        value
          // thousand separator (space or non breaking space)
          .replace(/\.|\s/g, '')
          .replace(',', '.'),
      );
  }
}
