import styled from '@emotion/styled';
import { Flex } from '@txt/core.styles';

/**
 * groups (as of now) Buttons horizontally with spaces
 * uses the (flex-)gap property
 *
 * takes all the properties of @txt/core.styles/Flex
 *
 * TODO: the removal of the margin should not be needed
 */
export const ButtonGroup = styled(Flex)`
  & > button,
  & > a {
    margin: 0 !important;
  }
  &:empty {
    display: none;
  }
`;

ButtonGroup.defaultProps = {
  gap: '2',
  alignItems: 'center',
  flexWrap: 'wrap',
};
