import styled from '@emotion/styled';
import * as React from 'react';
import { TypoProps, typoStyles } from './utils';

// Inspired by Chakras `as`prop (https://chakra-ui.com/docs/components/heading)
type HeadingTag = Extract<keyof React.JSX.IntrinsicElements, 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>;

export const Heading = styled.h1<TypoProps>((props) =>
  typoStyles({
    ...props,
    fontSize: props.fontSize ?? 'xl4',
    fontWeight: props.fontWeight ?? 'semiBold',
    lineHeight: props.lineHeight ?? 'heading',
  }),
) as <T extends HeadingTag>(props: PolymorphicComponentPropsWithRef<T, TypoProps>) => React.ReactElement;
