import { useSyncExternalStore } from 'react';

export function createUseGlobal<T>(initial: T) {
  let global = initial;

  const listeners = new Set<() => void>();

  function subscribe(callback: () => void) {
    listeners.add(callback);
    return () => listeners.delete(callback);
  }

  function getSnapshot() {
    return global;
  }

  function notify() {
    for (const listener of listeners) {
      listener();
    }
  }

  function set(value: T | ((old: T) => T)) {
    global = value instanceof Function ? value(global) : value;
    notify();
  }

  function useGlobalState() {
    const watched = useSyncExternalStore(subscribe, getSnapshot);

    return [watched, set] as const;
  }

  return useGlobalState;
}
