import qs from 'query-string';

/** Parse an url search string. */
export const parseQueryString = qs.parse;

/** Stringlifies url parameters as search string. */
export const encodeQueryString = qs.stringify;

export type UrlParams = {
  [name: string]: number | string | string[] | boolean | undefined;
};

/**
 * Adds the query string to the given url.
 * Does not add the dangling ? if no params given.
 * Its possible to throw in a url with existing parameters,
 * parameters will be merged. Given params will take precedence.
 *
 * @returns new url with queryString or unmodified url
 */
export const urlWithQueryString = (url: string, params?: UrlParams) => {
  if (!params) {
    return url;
  }

  const parsedUrl = qs.parseUrl(url);
  const existingQueryParams = parsedUrl.query;

  const queryString = encodeQueryString({
    ...existingQueryParams,
    ...params,
  });

  if (!queryString) {
    return url;
  }

  return `${parsedUrl.url}?${queryString}`;
};
