import { css } from '@emotion/react';

/**
 * This function should be used to handle text-overflow.
 * It will break long words and avoid horizontal overflowing of text
 * @returns css props to handle overflow
 */
export const overflowXStyle = () => css`
  overflow-x: hidden;
  overflow-wrap: anywhere;
  word-break: normal;
`;
