import * as React from 'react';
import { createIntl } from 'react-intl';

type Props = {
  value: number;
};

// Needs to force English locale because e.g. German has another abbreviation which only starts at millions
const forceIntlEnglish = createIntl({ locale: 'en' });

/**
 * Formats a number to a compact abbreviation.
 * e.g. 1200 => 1.2K
 *      1000000 => 1M
 */
export const formattedCompactNumber = (value: number) => forceIntlEnglish.formatNumber(value, { notation: 'compact' });

export const FormattedCompactNumber: React.FC<Props> = ({ value }) => <span>{formattedCompactNumber(value)}</span>;
