import styled from '@emotion/styled';
import * as React from 'react';

type Props = {
  className?: string;
  compact?: boolean;
};

const TxtureLogoSVG: React.FC<Props> = ({ className, compact = false }) => (
  <LogoSvg
    height={75}
    viewBox={`0 0 ${compact ? 68 : 254} 75.149998`}
    width={compact ? 68 : 254}
    y="0px"
    x="0px"
    version="1.1"
    className={className}
  >
    <g>
      {!compact && (
        <>
          <path d="m100.9 57.325c-1.3.2-3.9.4-6.3.4-6.2 0-9.7-2-9.7-9.1v-34c0-.7.6-1.1 1.3-1.3l3.2-.5h.1c.7 0 1.1.6 1.1 1.3v7h10.3c.7 0 1.3.5 1.3 1.2v2.2c0 .7-.5 1.2-1.3 1.2h-10.3v22.8c0 3.8 1.3 4.5 4.9 4.5h5.3c.9 0 1.4.3 1.4.9v2.2c0 .7-.3 1.1-1.3 1.2z" />
          <path d="m137.1 57.225h-4.5c-.5 0-.7-.1-.9-.5l-9.4-13.8-9.4 13.6c-.4.6-.5.7-1.1.7h-3.9c-.5 0-.8-.3-.8-.7 0-.2.1-.5.3-.9l11.8-16.9-11.2-16.1c-.2-.3-.3-.6-.3-.9 0-.5.4-.8 1-.8h4.3c.5 0 .9.2 1.3.9l8.4 12.4 8.4-12.4c.6-.9.8-.9 1.4-.9h3.8c.5 0 .9.3.9.8 0 .3-.1.5-.3.9l-11.1 15.7 12 17.3c.2.3.3.5.3.8-.1.5-.3.8-1 .8z" />
          <path d="m159.5 57.325c-1.3.2-3.9.4-6.3.4-6.2 0-9.7-2-9.7-9.1v-34c0-.7.6-1.1 1.3-1.3l3.2-.5h.1c.7 0 1.1.6 1.1 1.3v7h10.3c.7 0 1.3.5 1.3 1.2v2.2c0 .7-.5 1.2-1.3 1.2h-10.3v22.8c0 3.8 1.3 4.5 4.9 4.5h5.3c.9 0 1.4.3 1.4.9v2.2c0 .7-.4 1.1-1.3 1.2z" />
          <path d="m180.9 57.725h-.6c-4.5 0-13.6-2.5-13.6-15.5v-19.9c0-.7.5-1.3 1.2-1.3h3.4c.7 0 1.2.6 1.2 1.3v19.9c0 7.9 3.4 10.4 7.8 10.4h.6c4.4 0 7.8-2.5 7.8-10.4v-19.9c0-.7.5-1.3 1.2-1.3h3.4c.7 0 1.2.6 1.2 1.3v19.9c0 13-9.1 15.5-13.6 15.5z" />
          <path d="m217.7 25.625c-4.5 0-6.6.1-9.1.7v29.6c0 .7-.5 1.3-1.2 1.3h-3.4c-.7 0-1.2-.6-1.2-1.3v-32c0-.7.5-1.2 1.2-1.5 3.8-1.6 8.3-1.8 13.8-1.8.9 0 1.4.1 1.4 1v3c-.1.7-.2 1-1.5 1z" />
          <path d="m251.3 41.025h-22.1v.8c0 7.6 3.6 11.1 9.6 11.1 3.8 0 8.2-.5 11.3-.9h.3c.5 0 1.1.3 1.1.9v2.2c0 .9-.3 1.3-1.3 1.5-3.8.7-6.9 1.1-11.3 1.1-7.6 0-15.4-3.6-15.4-16.3v-4.6c0-9.3 5.2-16.3 14.6-16.3 9.6 0 14.5 6.8 14.5 16.2v2.8c-.1.9-.5 1.5-1.3 1.5zm-4.6-4.8c0-7-2.8-10.6-8.6-10.6-5.7 0-8.8 3.8-8.8 10.6v.3h17.4v-.3z" />
        </>
      )}

      <path
        className="txt__ico"
        d="m64.2 18.625-30.3-17.4c-.6-.3-1.3-.3-1.9 0l-30.2 17.4c-.5.3-.8.8-.8 1.3v35.1c0 .5.3 1 .8 1.3l30.4 17.6c.5.3 1.1.3 1.5 0l30.4-17.6c.5-.3.8-.8.8-1.3v-35c0-.6-.3-1.1-.7-1.4zm-31.2 48.1c-.6 0-1.2-.1-1.6-.4l-23.8-13.7c-.9-.5-.9-1.3 0-1.9l7.2-4.2 14.9 8.6c.9.5 2.1.8 3.3.8s2.3-.3 3.3-.8l14.9-8.6 7.2 4.2c.9.5.9 1.3 0 1.9l-23.8 13.7c-.4.3-1 .4-1.6.4zm0-58.2c.6 0 1.2.1 1.6.4l23.8 13.7c.9.5.9 1.3 0 1.9l-7.2 4.2-1.6.9-1.6.9-9 5.2-1.6.9-1.6.9-1.1.6c-.4.3-1 .4-1.6.4s-1.2-.1-1.6-.4l-1.1-.6-1.6-.9-1.8-.9-8.9-5.1-1.6-.9-1.6-.9-7.3-4.3c-.9-.5-.9-1.3 0-1.9l23.8-13.7c.4-.2 1-.4 1.6-.4zm-18.2 34.2-7.2-4.2c-.9-.5-.9-1.3 0-1.9l7.2-4.2 8.9 5.1 1.6.9 1.7 1.1 2.8 1.6c.9.5 2.1.8 3.3.8s2.3-.3 3.3-.8l2.6-1.6 1.6-.9 1.6-.9 8.9-5.1 7.2 4.2c.9.5.9 1.3 0 1.9l-7.2 4.2-1.6.9-1.6.9-13.3 7.7c-.4.3-1 .4-1.6.4s-1.2-.1-1.6-.4l-13.3-7.7-1.6-.9-1.7-1.1z"
      />
    </g>
  </LogoSvg>
);

const LogoSvg = styled.svg`
  path {
    fill: ${(props) => props.theme.palette?.primary[500] || '#22d6b2'};
    stroke: none;
  }
`;

export const TxtureLogo = styled(TxtureLogoSVG)<{ logoColor?: 'green' | 'black' | 'white' }>`
  path {
    fill: ${(props) =>
      props.logoColor === 'black' ? '#000' : props.logoColor === 'white' ? '#fff' : props.theme.palette.primary[500]};
    stroke: none;
  }
`;
