import { faClipboard } from '@fortawesome/pro-solid-svg-icons';
import { Text } from '@txt/core.styles';
import * as React from 'react';
import { useCopyToClipboard } from 'react-use';
import { FormattedMessage, formatMessage } from '../i18n';
import { Button } from './Button';

type Props = {
  text: string;
  mode?: 'inline' | 'button';
  onSuccess?: (message: string) => void;
  onError?: (message: string, error?: Error) => void;
  className?: string;
};

export const ClipboardButton: React.FC<Props> = ({ text, mode = 'inline', onSuccess, onError, className }) => {
  const [copyState, copyToClipboard] = useCopyToClipboard();

  React.useEffect(() => {
    if (copyState.error) {
      onError?.(formatMessage('clipboardButton.notification.error'), copyState.error);
    }
  }, [copyState]);

  function copy() {
    copyToClipboard(text);
    onSuccess?.(formatMessage('clipboardButton.notification.success'));
  }

  if (mode === 'inline') {
    return (
      <Text>
        <Button
          variant="text-grey"
          className={className}
          tooltip={<FormattedMessage id="clipboardButton.title" />}
          onClick={copy}
        >
          <Button.Icon icon={faClipboard} />
        </Button>
      </Text>
    );
  }

  return (
    <Button variant="contained" onClick={copy} className={className}>
      <Button.Icon icon={faClipboard} />
      <Text>
        <FormattedMessage id="clipboardButton.title" />
      </Text>
    </Button>
  );
};
