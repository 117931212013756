import { css, jsx } from '@emotion/react';
import * as React from 'react';

export const PREVENT_DRAGGING_CLASSNAME = 'draggable-capture-interaction';

/**
 * This hook enables horizontal scroll by dragging the mouse in a container
 *
 * The returned object has a ref, the mouse handlers (down, move and up) and css styling
 * The dragging is only activated if the container is scrollable
 *
 * @returns html props which have to be set to a html element
 */
export const useDraggableScroll = () => {
  const [isScrollable, setIsScrollable] = React.useState(false);
  const lastClientXRef = React.useRef(0);
  const scrollElementRef = React.useRef<HTMLElement | null>(null);

  const [observer] = React.useState(
    () =>
      new ResizeObserver(([entry]) => {
        if (!entry) {
          return;
        }

        const hasScrollableContent = entry.target.scrollWidth > entry.target.clientWidth;
        const overflowXStyle = window.getComputedStyle(entry.target).overflowX;
        const isOverflowHidden = overflowXStyle.indexOf('hidden') !== -1;

        setIsScrollable(hasScrollableContent && !isOverflowHidden);
      }),
  );

  const handleMouseMove = (event: MouseEvent) => {
    if (!scrollElementRef.current) {
      return;
    }

    const movementX = lastClientXRef.current - event.clientX;
    const currentScroll = scrollElementRef.current.scrollLeft + movementX;
    lastClientXRef.current = event.clientX;
    scrollElementRef.current.scrollLeft = currentScroll;
  };

  const handleStart: React.MouseEventHandler<HTMLElement> = (event) => {
    // Prevent dragging if the user is interacting with a clickable or custom element
    //
    // and
    //
    // as events bubble up the react tree and not the dom tree we check if we are inside of the container. this helps
    // with situations where a portal (Modal) is rendered within the draggable component.
    if (
      event.target instanceof Element &&
      (event.target.closest(`a, button, input, select, textarea, .${PREVENT_DRAGGING_CLASSNAME}`) ||
        !scrollElementRef.current?.contains(event.target))
    ) {
      return;
    }

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleStop);

    // initialize
    lastClientXRef.current = event.clientX;
  };

  const handleStop = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleStop);
  };

  // Making sure to remove the eventlistener on derister
  React.useEffect(() => handleStop, []);

  return {
    ref: (ref: HTMLElement | null) => {
      scrollElementRef.current = ref;
      if (ref) {
        observer.observe(ref);
      } else {
        observer.disconnect();
      }
    },
    ...(isScrollable && {
      onMouseDown: handleStart,
      css: styles,
    }),
  } satisfies React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
};

type DraggableProps = { children: React.ReactElement };

export const Draggable: React.FC<DraggableProps> = ({ children }) =>
  jsx(children.type, { ...children.props, ...useDraggableScroll() });

const styles = css`
  cursor: grab;
  user-select: none;

  &:active {
    cursor: grabbing;
  }
`;
