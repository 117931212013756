import * as React from 'react';
import { Button } from './Button';

type Props = React.ComponentProps<typeof Button> & {
  onClick: () => Promise<any>;
  icon?: React.ReactElement;
};

const Root: React.FC<Props> = ({ onClick, icon, ...props }) => {
  const [isSaving, setIsSaving] = React.useState(false);

  const handleClick = async () => {
    try {
      setIsSaving(true);
      await onClick();
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Button {...props} onClick={handleClick}>
      {isSaving ? <Button.Icon icon="spinner" className="fa-pulse fa-3x fa-fw" fixedWidth /> : icon || null}
      {props.children}
    </Button>
  );
};

export const AsyncButton = Object.assign(Root, { Icon: Button.Icon });
